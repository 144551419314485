import React, { useContext } from 'react';
import { AtomSpinner } from 'react-epic-spinners';
import FuserContext from '../../context/FuserContext';
interface FuserLoaderProps {
  loading: boolean;
  children?: React.ReactNode;
  name?: string;
  message?: string;
  onCancelClick?: any;
}

const FuserLoader: React.FC<FuserLoaderProps> = ({
  loading,
  children,
  name,
  message,
  onCancelClick,
}) => {
  if (loading) {
    // return <><AtomSpinner color="blue"/>Loading {name}...</>;
    return (
      <>
        <div className='relative'>
          <div className='absolute -top-4 left-0 h-0 flex gap-2 my-4'>
            <AtomSpinner color='blue' className='shrink-0'/>
            {message ? (
              <p className={`${
                message.length > 125 ? 'text-xs' :
                message.length > 100 ? 'text-sm' : ''
              } sm:text-base`}>
                {message}
              </p>
            ) : name ? (
              <p>Loading {name}...</p>
            ) : null}{' '}
            {/* <span>Loading {name}...</span> */}
          </div>
          <div
            className='coverDiv'
            style={{
              opacity: 0.2,
              userSelect: 'none',
              pointerEvents: 'none'
            }}
          >
            {children}
          </div>
          <span className='flex items-center justify-start'>
            {onCancelClick && (
              <button
                onClick={onCancelClick}
                title='Cancel'
              >
                Cancel
              </button>
            )}
          </span>
        </div>
      </>
    );
  }
  //console.log("message",message);
  //return <>{message ? <p>{message}</p>:null} <div><span style={{position: 'relative', height:0, display:"block"}}><AtomSpinner color="blue"/>Loading {name}...</span><div className='coverDiv' style={{opacity: 0.2, userSelect: 'none', pointerEvents: 'none'}}>{children}</div></div></>;

  return <>{children}</>;
};

export default FuserLoader;
