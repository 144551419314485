import React, { FC, useContext, useEffect, useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import { MdContentCopy } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import FuserContext from "../../context/FuserContext";
import Block from "../../models/Block";
import useShallowEqualMemo from "../../hooks/useShallowEqualMemo";
import {
  blockComponents,
  blockDataKeysHoldingBlockNumbers,
  blockDataKeysHoldingReferences,
  blockTypesExpandedByDefault,
} from "../../constants/blocks";
import { iconStyle, menuButtonStyles } from "../../constants/styles";
import { deleteAtIndex, updateAtIndex } from "../../utils/array";
import { useAuthUser } from "react-auth-kit";
import { v4 as uuidv4 } from "uuid";

const sharableBlockTypes = ["prompt", "info", "image", "textToSpeech", "chat"];

const BlockOptions: FC<{ block: any; index: number }> = ({ block, index }) => {
  const {
    blocks,
    runnerMode,
    setBlocks,
    collapsedBlocks,
    setCollapsedBlocks,
    setShareModalOpen,
    setBlockToShare,
    setIsLoading,
    isLoading,
    addBlock,
    setActivityLog,
    setSelectedBlockId,
    // toolId,
    // replacePlaceholders,
    // setShareMessage,
  } = useContext(FuserContext);

  const user = useAuthUser()();

  const updateBlocks = () => setBlocks(updateAtIndex(index, block, blocks));

  const toggleBlockCollapse = (blockId: string) => {
    if (collapsedBlocks.includes(blockId)) {
      setCollapsedBlocks(
        collapsedBlocks.filter((id: string) => id !== blockId)
      );
    } else {
      setCollapsedBlocks([...collapsedBlocks, blockId]);
    }
  };

  const handleCollapseAfterRunningClick = (e: any) => {
    const oldValue = block.collapseAfterRunning;

    // will be undefined for old tools, default is true
    const newValue = [true, undefined].includes(oldValue) ? false : true;

    setBlocks((previousBlocks: Block[]) =>
      updateAtIndex(
        index,
        { ...block, collapseAfterRunning: newValue },
        previousBlocks
      )
    );
  };

  // const [statusMessage, setStatusMessage] = useState<string>('');

  const [editingBlockName, setEditingBlockName] = useState(false);

  const blockIds = useShallowEqualMemo(blocks.map(({ id }: any) => id));

  useEffect(() => {
    // discard any pending changes if the blocks have moved around
    setEditingBlockName(false);
  }, [blockIds]);

  const handleBlockNameChange = (e: any) => {
    block.data.name = e.target.value;
    updateBlocks();
  };

  const handleSaveBlockName = (e: any) => {
    const trimmedBlockName = block.data.name.trim();

    if (/^Block\d+$/.test(trimmedBlockName)) {
      return alert(
        "Names of the form Block#number# are reserved, please choose a different name"
      );
    }

    const otherBlockNames = blocks
      .map(({ data: { name } }: any) => name)
      .filter(
        (name: string, blockIndex: number) =>
          name !== undefined && blockIndex !== index
      );

    // console.log(
    //   'otherBlockNames',
    //   otherBlockNames,
    //   'trimmed input name',
    //   trimmedBlockName
    // );

    if (otherBlockNames.includes(trimmedBlockName)) {
      const indexOfBlockWithInputName = blocks
        .map(({ data: { name } }: any) => name)
        .findIndex((name: string) => name === trimmedBlockName);
      return alert(
        `This name has been taken at block ${indexOfBlockWithInputName}, please choose a different name.`
      );
    }

    if (!/^\w+$/.test(trimmedBlockName)) {
      return alert(
        "The name must consist of one or more letters/numbers/underscores and no other characters"
      );
    }

    const blocksWithUpdatedReferences = blocks.map((block: Block) => {
      const newDataKeyValuePairs = blockDataKeysHoldingReferences
        .filter((key: string) => block.data[key] !== undefined)
        .map((key: string) => {
          // console.log(block.data[key]);
          return [
            key,
            block.data[key]
              .toString()
              .replace(
                new RegExp(`@${lastSavedBlockName}(\\W|$)`, "g"),
                (match: string, nextCharacter: string) => {
                  // console.log(
                  //   'lastSavedBlockName',
                  //   lastSavedBlockName,
                  //   'match',
                  //   match,
                  //   'nextCharacter:',
                  //   nextCharacter
                  // );
                  return `@${trimmedBlockName}${nextCharacter}`;
                }
              ),
          ];
        });
      return {
        ...block,
        data: {
          ...block.data,
          ...Object.fromEntries(newDataKeyValuePairs),
        },
      };
    });

    // console.log(blocksWithUpdatedReferences);

    setBlocks(blocksWithUpdatedReferences);

    setEditingBlockName(false);
    //alert('name saved');
    // console.log('otherBlockNames:', otherBlockNames);
  };

  const [lastSavedBlockName, setLastSavedBlockName] = useState(
    block.data.name ?? ""
  );

  const handleEditBlockNameClick = () => {
    setLastSavedBlockName(block.data.name);
    setEditingBlockName(true);
  };

  const handleDiscardBlockNameChanges = () => {
    block.data.name = lastSavedBlockName;
    updateBlocks();
    setEditingBlockName(false);
  };

  const startShareProcess = () => {
    if (user?.trialAccount) {
      return alert("Please register your account to share this block.");
    }
    setBlockToShare(block);
    setShareModalOpen(true);
  };

  const blockType = block.blocktype ?? block.type?.replace("blockdiv-", "");

  const isSharable =
    sharableBlockTypes.includes(blockType) &&
    !(blockType === "prompt" && !block.data.showOutputToUser) &&
    !(blockType === "info" && block.data.selectValue === "internal") &&
    !collapsedBlocks.includes(block.id);

  const shareButton = isSharable && (
    <button className={menuButtonStyles} onClick={startShareProcess}>
      Share
    </button>
  );

  return (
    <div className="flex flex-col gap-2 items-end justify-start grow">
      {!runnerMode && (
        <div className="w-full flex flex-col gap-2 sm:flex-row justify-end">
          {(block.data.name || editingBlockName) && (
            <label className="flex grow sm:grow-0 sm:w-72 gap-1 items-center justify-end">
              Name:
              {/* (optional, only letters, numbers and underscores allowed,
                  no spaces) */}
              {editingBlockName ? (
                <input
                  className="w-full border border-black p-1 rounded-lg"
                  onChange={handleBlockNameChange}
                  value={block.data.name ?? ""}
                />
              ) : (
                <p>{block.data.name}</p>
              )}
            </label>
          )}
          {editingBlockName && (
            <div className="flex justify-center gap-2">
              <button
                className={menuButtonStyles}
                onClick={handleSaveBlockName}
              >
                ✔
              </button>
              <button
                className={menuButtonStyles}
                onClick={handleDiscardBlockNameChanges}
              >
                ❌
              </button>
            </div>
          )}
        </div>
      )}
      <div className="w-full flex flex-col md:flex-row items-end md:justify-end gap-2">
        <div className={`w-auto ${runnerMode ? 'h-max' : 'h-24'} sm:h-16 flex gap-3 items-center justify-between p-2 bg-gradient-to-r from-blue-100 via-purple-200 to-blue-100 rounded-md shadow-lg dark:bg-neutral-800`}>
          {runnerMode || (
            <div className="flex xl:flex-row gap-2 items-center">
              <button
                className={menuButtonStyles}
                onClick={handleEditBlockNameClick}
              >
                {`${!block.data.name ? "Add block" : "Edit"} name`}
              </button>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <div className="text-xs flex gap-4">
              <span>Block: {index}</span>
              {runnerMode || (
                <span>
                  Type:{" "}
                  {blockComponents?.[block?.type]?.selectName?.replace(
                    /block/gi,
                    ""
                  )}
                </span>
              )}
            </div>
            {runnerMode || (
              <div className="flex items-center gap-2">
                <label
                  className="text-xs align-middle"
                  htmlFor={`collapseAfterRunning-${index}`}
                >
                  Collapse block after running
                </label>
                <input
                  id={`collapseAfterRunning-${index}`}
                  type="checkbox"
                  onChange={handleCollapseAfterRunningClick}
                  checked={
                    block.collapseAfterRunning !== undefined
                      ? block.collapseAfterRunning
                      : blockTypesExpandedByDefault.includes(block.type)
                        ? false
                        : true
                  }
                />
              </div>
            )}
          </div>
          {!runnerMode && shareButton}
        </div>
        <div className="h-16 flex sm:w-max gap-1 md:gap-3 items-center justify-between p-2 bg-gradient-to-r from-blue-100 via-purple-200 to-blue-100 rounded-md shadow-lg dark:bg-neutral-800">
          {!runnerMode && (
            <>
              <button
                className={menuButtonStyles}
                onClick={() => duplicateBlock(index)}
                title="Duplicate Block"
              >
                <MdContentCopy className={iconStyle} />
              </button>
              <button
                className={menuButtonStyles}
                onClick={() => moveBlockUp(index)}
                title="Move Block Up"
              >
                <AiOutlineArrowUp className={iconStyle} />
              </button>
              <button
                className={menuButtonStyles}
                onClick={() => moveBlockDown(index)}
                title="Move Block Down"
              >
                <AiOutlineArrowDown className={iconStyle} />
              </button>
              <button
                className={menuButtonStyles}
                onClick={() => deleteBlock(block.id)}
                title="Delete Block"
              >
                <GiTrashCan className={iconStyle} />
              </button>
            </>
          )}
          {runnerMode && shareButton}
          <button
            className={menuButtonStyles}
            onClick={() => toggleBlockCollapse(block.id)}
            title={`${
              collapsedBlocks.includes(block.id) ? "Expand" : "Collapse"
            } Block`}
          >
            {collapsedBlocks.includes(block.id) ? (
              <BiChevronUpCircle className={iconStyle} />
            ) : (
              <BiChevronDownCircle className={iconStyle} />
            )}
          </button>
          {runnerMode || (
            <button
              className={`${menuButtonStyles}`}
              onClick={() => addBlock(index)}
            >
              <FiPlus className={`${iconStyle}`} />
            </button>
          )}
        </div>
      </div>
    </div>
  );

  function moveBlockUp(index: number) {
    if (index === 0) {
      return; // Block is already at the top
    }

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${index}|${index - 1})(?=\\W|$)`,
      "g"
    );

    const atReferenceReplacement = (
      atReference: string,
      blockIndexString: string
    ) => {
      const newBlockIndex = +blockIndexString === index ? index - 1 : index;
      return `@Block${newBlockIndex}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${index}|${index - 1}):(input|output)>`,
      "g"
    );

    const blockReferenceReplacement = (blockReference: string) => {
      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = +match[1] === index ? index - 1 : index;
        return "<" + newRef.toString() + ":" + match[2] + ">";
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      const oldBlockNumber = +blockNumberString;
      return oldBlockNumber === index - 1
        ? index
        : oldBlockNumber === index
          ? index - 1
          : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const [previousBlock, currentBlock] = [
        newBlocks[index - 1],
        newBlocks[index],
      ];
      newBlocks[index] = previousBlock;
      newBlocks[index - 1] = currentBlock;

      return newBlocks.map((block) => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter((key) => block.data?.[key]?.toString() !== undefined)
            .map((key) => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter((key) => block.data?.[key] !== undefined)
            .map((key) => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog((prevLog: any) => [
      ...prevLog,
      `Moved block from index ${index} to index ${index - 1}`,
    ]);
  }

  function duplicateBlock(index: number) {
    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const blockToDuplicate = blocks[index];
      const duplicateBlock = {
        ...blockToDuplicate,
        id: uuidv4(),
        data: { ...blockToDuplicate.data, name: undefined },
      };
      newBlocks.splice(index + 1, 0, duplicateBlock);
      const blockIndicesToChange = blocks
        .map((_, blockIndex) => blockIndex)
        .filter((blockIndex) => blockIndex >= index + 1);

      const atReferencesNeedingUpdate = new RegExp(
        `@Block(${blockIndicesToChange.join("|")})(?=\\W|$)`,
        "g"
      );

      const atReferenceReplacement = (
        atReference: string,
        blockNumberString: string
      ) => {
        return `@Block${+blockNumberString + 1}`;
      };

      const blockReferenceNeedingUpdate = new RegExp(
        `<(${blockIndicesToChange.join("|")}):(input|output)>`,
        "g"
      );

      const blockReferenceReplacement = (blockReference: string) => {
        const regex = /<(\d+):(output|input)>/g;
        let match: RegExpExecArray | null;
        while ((match = regex.exec(blockReference)) !== null) {
          const newRef = parseInt(match[1]) + 1;
          return "<" + newRef.toString() + ":" + match[2] + ">";
        }
      };

      const blockNumberReplacement = (blockNumberString: string) => {
        return blockIndicesToChange.includes(+blockNumberString)
          ? +blockNumberString + 1
          : blockNumberString;
      };

      return newBlocks.map((block) => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter((key) => block.data?.[key]?.toString() !== undefined)
            .map((key) => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter((key) => block.data?.[key] !== undefined)
            .map((key) => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    const newIsLoading = [...isLoading];
    newIsLoading.splice(index + 1, 0, false);
    setIsLoading(newIsLoading);

    setActivityLog((prevLog: any) => [
      ...prevLog,
      `Duplicated block at index: ${index}`,
    ]);
  }

  function deleteBlock(idToDelete: string) {
    const indexOfBlockToDelete = blocks.findIndex(
      (block: any) => block.id === idToDelete
    );

    setIsLoading(deleteAtIndex(indexOfBlockToDelete, isLoading));

    const blockIndicesToChange = blocks
      .map((_: any, blockIndex: number) => blockIndex)
      .filter((blockIndex: number) => blockIndex > indexOfBlockToDelete);

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${blockIndicesToChange.join("|")})(?=\\W|$)`,
      "g"
    );

    const atReferenceReplacement = (
      atReference: string,
      blockNumberString: string
    ) => {
      return `@Block${+blockNumberString - 1}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${blockIndicesToChange.join("|")}):(input|output)>`,
      "g"
    );

    const nameOfBlockToDelete = blocks[indexOfBlockToDelete].data.name;
    // delete any references to the deleted block along with any trailing space
    const patternsToDelete = [
      `<${indexOfBlockToDelete}:(input|output)> ?`,
      `@Block${indexOfBlockToDelete} `,
      `@Block${indexOfBlockToDelete}(?=\\W|$)`,
      ...(nameOfBlockToDelete
        ? [`@${nameOfBlockToDelete} `, `@${nameOfBlockToDelete}(?=\\W|$)`]
        : []),
    ];

    const blockReferencesToDelete = new RegExp(patternsToDelete.join("|"), "g");

    const blockReferenceReplacement = (blockReference: string) => {
      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = parseInt(match[1]) - 1;
        return "<" + newRef.toString() + ":" + match[2] + ">";
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      return blockIndicesToChange.includes(+blockNumberString)
        ? +blockNumberString - 1
        : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = blocks.filter((block) => block.id !== idToDelete);

      return newBlocks.map((block) => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter((key) => block.data?.[key]?.toString() !== undefined)
            .map((key) => [
              key,
              block.data[key]
                .toString()
                ?.replace(blockReferencesToDelete, "")
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter((key) => block.data?.[key] !== undefined)
            .map((key) => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog((prevLog: any) => [
      ...prevLog,
      `Deleted block with ID: ${idToDelete}`,
    ]);
    setSelectedBlockId(null);
  }

  function moveBlockDown(index: number) {
    if (index === blocks.length - 1) {
      return; // Block is already at the bottom
    }

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${index}|${index + 1})(?=\\W|$)`,
      "g"
    );

    const atReferenceReplacement = (
      atReference: string,
      blockIndexString: string
    ) => {
      const newBlockIndex = +blockIndexString === index ? index + 1 : index;
      return `@Block${newBlockIndex}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${index}|${index + 1}):(input|output)>`,
      "g"
    );
    const blockReferenceReplacement = (blockReference: string) => {
      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = +match[1] === index ? index + 1 : index;
        return "<" + newRef.toString() + ":" + match[2] + ">";
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      const oldBlockNumber = +blockNumberString;
      return oldBlockNumber === index + 1
        ? index
        : oldBlockNumber === index
          ? index + 1
          : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const tempBlock = newBlocks[index];
      newBlocks[index] = newBlocks[index + 1];
      newBlocks[index + 1] = tempBlock;
      return newBlocks.map((block) => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter((key) => block.data?.[key]?.toString() !== undefined)
            .map((key) => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter((key) => block.data?.[key] !== undefined)
            .map((key) => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog((prevLog: any) => [
      ...prevLog,
      `Moved block from index ${index} to index ${index + 1}`,
    ]);
  }
};

export default BlockOptions;
