import React, { FC, useEffect, useState } from "react";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const MyToolTips: FC<{ content: string; tipID: string; datatooltipplace:string }> = ({ content, tipID, datatooltipplace="bottom" }) => {
  const [screenWidthInPx, setScreenWidthInPx] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidthInPx(window.innerWidth)
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const smallScreen = screenWidthInPx < 640;

  const tooltipID = `my-tooltip-${tipID}`;

  return (
    <span style={{zIndex:1}}>
      <style>{`
        #MyToolTips p {
          margin: ${smallScreen ? '0' : '10'}px;
          z-index: 1;
        }
        #MyToolTips th, #MyToolTips td {
          ${smallScreen ? 'padding: 4px' : ''};
        }
        #MyToolTips {
          font-size: ${smallScreen ? '0.75' : '1'}rem;
          ${smallScreen ? 'line-height: 1.25' : ''}
        }
      `}</style>
      <Tooltip
        id={tooltipID}
        style={{
          maxWidth: "50%",
          zIndex: 99,
          ...(smallScreen && {padding: '0.5rem'})
        }}
      />
      <span 
        data-tooltip-html={`<div class="text-base" id="MyToolTips">${content}</div>`}
        data-tooltip-place=  {datatooltipplace as any}
        data-tooltip-id={tooltipID}
        className="rounded-full border-2 border-slate-500 w-fit p-1"
      >
        ?
      </span>
    </span>
  );
};

export default MyToolTips;
