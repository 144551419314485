'use client';
import { ErrorBoundary } from 'react-error-boundary';
import React, { FC, useContext, ChangeEvent } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import BlockOptions from './BlockOptions';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import MyToolTips from '../../components/MyTooltip';
import { blockComponents, blockDataKeysHoldingBlockNumbers, blockDataKeysHoldingReferences, blockReferenceRegex, initialBlockData } from '../../constants/blocks';
import {
  prettyPrintMultidimensionalStringArray,
  updateAtIndex,
} from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { v4 as uuidv4 } from "uuid";
import { useAuthUser } from 'react-auth-kit';

// import TestErrorBoundary from '../../components/TestErrorBoundary';

const BlockFallback = ({ error }: any) => (
  <div className='gap w-full h-48 flex items-center justify-center'>
    Sorry, this block has crashed
    {error?.message ? ` with the following error: ${error.message}` : ''}
  </div>
);

const BlocksFallback = ({ error }: any) => (
  <div className='gap w-full h-48 flex items-center justify-center'>
    Sorry, this tool has crashed
    {error?.message ? ` with the following error: ${error.message}` : ''}
  </div>
);

const onBlockError = (error: Error) => {
  ReactGA.event('block_crashed', {
    errorMessage: error?.message,
    stack: error?.stack,
  });
};

const onBlocksError = (error: Error) => {
  ReactGA.event('tool_crashed', {
    errorMessage: error?.message,
    stack: error?.stack,
  });
};

const isHiddenInRunnerMode = (block: any) => {
  return (
    [
      'blockdiv-processing',
      'blockdiv-reducing',
      'blockdiv-custom-javascript',
    ].includes(block?.type) ||
    (block?.type === 'blockdiv-info' && block?.data?.selectValue !== 'external')
  );
};

const BlocksDisplay: FC<{}> = () => {
  const {
    blocks,
    setBlocks,
    collapsedBlocks,
    runnerMode,
    isJsonVisible,
    setIsLoading,
    stillRunning,
    isLoading,
    updateBlockData,
    handleChange,
    imageURLsForBlockWithId,
    runnerIndex,
    blockRefs,
    minimalMode,
    templates,
    setActivityLog
    // blockStyles,
  } = useContext(FuserContext);

  const location = useLocation();

  const user = useAuthUser()();

  const blockTypes = Object.keys(blockComponents).filter(
    key => user?.loggedin === 'false'
      ? true
      : !['blockdiv-google-ads'].includes(key)
  );

  const renderBlock = (block: Block, index: number): JSX.Element | null => {
    const path = location.pathname;
    const pathParts = path.split('/');
    let lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
    if (lastSegment === undefined) lastSegment = 'PROBLEM GETTING TOOLid';
    //console.log("last segment in fusr",lastSegment,path,location.pathname,pathParts);

    const response = block?.data?.response ?? undefined;

    const { type } = block;

    // console.log({ minimalMode });

    if (blockTypes.includes(type)) {
      const Block = blockComponents[type].component;
      //console.log("in fuser",lastSegment);
      const blockClasses = `border-2 my-4 p-4 flex ${
        runnerMode &&
        collapsedBlocks.includes(blocks[index].id) &&
        block.type !== 'blockdiv-textToSpeech'
          ? 'flex-row'
          : 'flex-col'
      } rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b ${
        runnerMode && index === runnerIndex ? 'border-blue-500 border' : ''
      } ${
        !runnerMode || index === runnerIndex
          ? 'from-blue-100 to-purple-200'
          : 'from-blue-50 to-purple-100'
      } '${!minimalMode ? 'transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in' : ''} dark:bg-neutral-800 ${
        runnerMode &&
        (isHiddenInRunnerMode(block) ||
          (minimalMode && collapsedBlocks.includes(blocks[index].id)))
          ? ' hidden'
          : ''
      }`;

      let resultHtml = '';
      if (response !== undefined) {
        try {
          resultHtml = truncateAfter(
            1000,
            Array.isArray(response)
              ? prettyPrintMultidimensionalStringArray(response)
              : response.toString()
          ).replace(
            /<(\/?)(script|style)>/g,
            (match: string, closingSlash: string, tagName: string) =>
              `&lt;${closingSlash}${tagName}&gt;`
          );
        } catch (error) {
          console.log('Error processing resultHtml:', error);
        }
      }

      return (
        <ErrorBoundary
          FallbackComponent={BlockFallback}
          onError={onBlockError}
          key={index}
        >
          {/* For testing block error boundary: {index === 1 && <TestErrorBoundary />} */}

          <div
            className={blockClasses}
            ref={el => {
              blockRefs.current[index] = el;
            }}
          >
            {/* <FuserLoader loading={true} /> */ null}

            <Block
              block={block}
              index={index}
              imageURLs={imageURLsForBlockWithId[block.id]}
              toolId={lastSegment}
              stillRunning={stillRunning}
              updateBlockData={updateBlockData(index)}
              updateBlocks={updateBlocks}
              isLoading={isLoading?.[index]}
              handleChange={e => handleChange(index, e)}
              setIsLoading={(newValue: boolean) =>
                setIsLoading(updateAtIndex(index, newValue, isLoading))
              }
              resultHtml={resultHtml}
              tool_id={lastSegment}
              collapsed={collapsedBlocks.includes(block.id)}
            />
            <BlockOptions
              block={block}
              index={index}
            />

            {isJsonVisible && (
              <pre className='border-2 p-2 rounded-xl bg-white'>
                {JSON.stringify(block, null, 2)}
              </pre>
            )}
          </div>
        </ErrorBoundary>
      );
    } else
      return (
        <div
          key={index}
          className='gap-2 sm:gap-8 w-full h-48 flex items-center flex-col sm:flex-row justify-center transition-all duration-500 ease-in-out transform hover:translate-y-[-10px] cursor-pointer'
        >
          <div className='flex items-center gap-1'>
            <p>Please select blocks type:</p>
            <select
              className='text-xs w-40 h-16 rounded-lg bg-transparent'
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                changeBlockType(index, e.target.value)
              }
            >
              <option value=''>choose block type</option>
              {blockTypes.map((key, i) => (
                <option
                  value={key}
                  key={i}
                >
                  {blockComponents[key].selectName}
                </option>
              ))}
            </select>
            <MyToolTips
              content="<p>block types</p>
              <p>There are many block types to choose from.
              <p>Usually you will want some <q>User Question</q> blocks early on. To gather information on the user's needs. e.g. a fitness tool may ask what their goals are.</p>
              <p>The <q>prompt</q> block allows you to send prompts to the AI.</p>
              <p>Embeddings blocks are for storing and searching for information, as a way of giving your tool knowledge, so you can add a lot of info and search for the bits you need based on the user's input.</p>
              <p>If you want to show the user a little message at the start of your tool choose an <q>info block</q> and set it to <q>show to user</q></p>"
              tipID={index.toString() + '-block-types-definition'}
              datatooltipplace='left'
            />
          </div>
          {templates?.length && (
            <div className='flex items-center gap-1'>
              <p>Or add a template:</p>
              <select
                className='text-xs w-40 h-16 rounded-lg bg-transparent'
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  blockToTemplate(
                    templates.find(({ _id }: any) => _id.toString() === e.target.value),
                    index
                  )
                }
              >
                <option value=''>choose template</option>
                {templates.map(({ _id, name }: any) => (
                  <option
                    value={_id}
                    key={_id}
                  >
                    {name}
                  </option>
                ))}
              </select>
              <MyToolTips
                content="Templates are groups of prepared blocks that perform certain tasks."
                tipID={index.toString() + '-block-templates-definition'}
                datatooltipplace='left'
              />
            </div>
          )}
        </div>
      );

    function updateBlocks() {
      return setBlocks((blocks: Block[]) =>
        updateAtIndex(index, block, blocks)
      );
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={BlocksFallback}
      onError={onBlocksError}
    >
      {blocks?.map((block: Block, index: number) => renderBlock(block, index))}
    </ErrorBoundary>
  );

  function changeBlockType(index: number, newType: string) {
    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        type: newType,
        data: initialBlockData[newType] ?? {},
      };
      return newBlocks;
    });
  }

  function blockToTemplate(template: any, index: number) {
    const atReferenceReplacement = (
      atReference: string,
      blockNumberString: string,
      offset: number
    ) => {
      return `@Block${+blockNumberString + offset}`;
    };

    const blockReferenceRegex = /<(\d+):(output|input)>/g;

    const blockReferenceReplacement = (blockReference: string, offset: number) => {
      let match: RegExpExecArray | null;
      while ((match = blockReferenceRegex.exec(blockReference)) !== null) {
        const newRef = parseInt(match[1]) + offset;
        // console.log(
        //   'match',
        //   match,
        //   '<' + newRef.toString() + ':' + match[2] + '>'
        // );
        return "<" + newRef.toString() + ":" + match[2] + ">";
      }
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];

      const templateBlocks = template.fields.map((block: any) => {
        // make a deep copy of the block to prevent modifications to the original template
        block = window.structuredClone(block); 
        block.id = uuidv4();
        for (const key of blockDataKeysHoldingReferences) {
          if (!block.data[key]) continue;
          block.data[key] = block.data[key]
            .replace(
              /@Block(\d+)(?=\W|$)/g,
              (reference: string, numberString: string) => atReferenceReplacement(
                reference, numberString, index
              )
            )
            .replace(
              blockReferenceRegex,
              (reference: string) => blockReferenceReplacement(
                reference,
                index
              )
            );
        }
        for (const key of blockDataKeysHoldingBlockNumbers) {
          block.data[key] = +block.data[key] + index;
        }
        return block;
      });

      newBlocks.splice(index, 1, ...templateBlocks);

      const numberOfBlocksAdded = template.fields.length - 1;
      
      const blockIndicesToChange = blocks
        .map((_, blockIndex) => blockIndex)
        .filter((blockIndex) => blockIndex >= index + 1);

      const atReferencesNeedingUpdate = new RegExp(
        `@Block(${blockIndicesToChange.join("|")})(?=\\W|$)`,
        "g"
      );

      //console.log(atReferencesNeedingUpdate);

      const blockReferenceNeedingUpdate = new RegExp(
        `<(${blockIndicesToChange.join("|")}):(input|output)>`,
        "g"
      );
      
      const blockNumberReplacement = (blockNumberString: string) => {
        return blockIndicesToChange.includes(+blockNumberString)
          ? +blockNumberString + numberOfBlocksAdded
          : blockNumberString;
      };

      //console.log("blockIndicesToChange",blockIndicesToChange);

      return newBlocks.map((block) => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter((key) => block.data?.[key]?.toString() !== undefined)
            .map((key) => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  (reference: string) => blockReferenceReplacement(reference, numberOfBlocksAdded)
                )
                ?.replace(
                  atReferencesNeedingUpdate,
                  (reference: string, numberString: string) => atReferenceReplacement(reference, numberString, numberOfBlocksAdded)
                ),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter((key) => block.data?.[key] !== undefined)
            .map((key) => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setIsLoading((oldIsLoading: any) => {
      const newIsLoading = [...oldIsLoading];
      newIsLoading.splice(index + 1, 0, false);
      return newIsLoading;
    });

    setActivityLog((prevLog: any) => [
      ...prevLog,
      `Added new block at index: ${index + 1}`,
    ]);
  }
};

export default BlocksDisplay;
