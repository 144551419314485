import React, { useState } from "react"

const ClassTogglableDiv = ({
  initialClass,
  alternateClass,
  commonClass,
  toggleAlternateText,
  toggleInitialText,
  innerHTML
}: any) => {
  const [className, setClassName] = useState(initialClass);

  const buttonText = className === initialClass
    ? toggleAlternateText
    : toggleInitialText;
  
  const onButtonClick = () => setClassName(
    className === initialClass
      ? alternateClass
      : initialClass
  );

  return (
    <>
      <div
        className={`${className} ${commonClass}`}
        dangerouslySetInnerHTML={{
          __html: innerHTML
        }}
      />
      <button
        className='border border-1 border-black px-1'
        onClick={onButtonClick}
      >
        {buttonText}
      </button>
    </>
  );
};

export default ClassTogglableDiv;
