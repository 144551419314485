import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MyRouter from './routers/index';
import { useAuthUser } from 'react-auth-kit';
import { CreditProvider } from './context/CreditContext';
import { BrowserSettingsProvider } from './context/BrowserSettingsContext';
import { useCookies } from 'react-cookie';
import './axiosConfig';
import { hotjar } from 'react-hotjar';
import { cookieDomain } from './constants/environmental';
import { daysToMilliseconds, futureDateAfterMilliseconds } from './utils/date';
hotjar.initialize({ id: 5031132, sv: 6 });
// hotjar.identify('USER_ID', { userProperty: 'value' });

function App() {
  const [cookies, setCookie] = useCookies(['referrerId', 'referralSource']);

  const user = useAuthUser()();
  
  const setReferrerCookiesIfApplicable = () => {
    // r is the id of the user that referred them
    const referrerId = new URLSearchParams(window.location.search).get('r');

    if (referrerId && cookies.referrerId !== referrerId) {
      setCookie('referrerId', referrerId, {
        // expires 7 days from now
        path: '/',
        expires: futureDateAfterMilliseconds(daysToMilliseconds(7)),
      });
    }

    const searchParams = new URLSearchParams(window.location.search);
    const referralSource =
      searchParams.get('utm_source') ||
      (searchParams.has('gclid')
        ? 'google ads'
        : searchParams.has('fbclid')
          ? 'facebook ads'
          : searchParams.has('msclkid')
            ? 'microsoft ads'
            : searchParams.get('ref') || document.referrer || 'direct visitor');

    // console.log('referral source:', referralSource);

    if (referralSource && !cookies.referralSource) {
      setCookie('referralSource', referralSource, {
        // expires 7 days from now
        domain: cookieDomain,
        path: '/',
        expires: futureDateAfterMilliseconds(daysToMilliseconds(7)),
      });
    }
  };

  useEffect(() => {
    setReferrerCookiesIfApplicable();
  }, []);

  return (
    <div
      className={`hide-logged-${user ? 'out' : 'in'}-children bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200`}
    >
      <Helmet>
        <script type='text/javascript'>
          {`
            _linkedin_partner_id = "5712906";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </script>
        <script
          type='text/javascript'
          src='https://snap.licdn.com/li.lms-analytics/insight.min.js'
        ></script>
        <noscript>
          {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=5712906&fmt=gif" />`}
        </noscript>
      </Helmet>
        <CreditProvider>
          <BrowserSettingsProvider>
            <MyRouter />
          </BrowserSettingsProvider>
        </CreditProvider>
    </div>
  );
}

export default App;
