import React, { FC, useState, useContext } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import BlockProps from '../../models/BlockProps';
import MyToolTips from '../MyTooltip';
import FuserContext from '../../context/FuserContext';
import useRunnerFocusOnEdit from '../../hooks/useRunnerFocusOnEdit';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import { ensureFlattenedToString, updateAtIndex, updateAtIndexRun } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import KeyValueInputForm from '../KeyValueInputForm';
import { backendURL } from '../../constants/environmental';
import axios from 'axios';
import { useAuthHeader } from 'react-auth-kit';
import TextareaAutosize from 'react-textarea-autosize';
import { useCredit } from '../../context/CreditContext';
import { replacePlaceholders } from '../../utils/fuser';

const ApiBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  handleChange,
  collapsed,
}) => {
  const {
    blockStyles,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    setCollapsedBlocks,
    toolId,
    setStillRunning,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();
  const { updateCredits } = useCredit();

  useRunnerFocusOnEdit(block, index);
  useBlockRunner(onSendClick, index);

  if (collapsed) {
    if (block.data.response)
      return (
        <>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, 'API response')}</div>
          <div>
            {truncateAfter(MAX_PREVIEW_CHARS, block.data.response.toString())}
          </div>
        </>
      );
    else return <div>API block</div>;
  }

  return (
    <FuserLoader
      name='API Block'
      loading={isLoading}
    >
      <div
        className={blockStyles}
        key={index}
      >
        {runnerMode ? (
          <p>API Block</p>
        ) : (
          <>
            <label className='flex gap-2 items-center'>
              HTTP Verb:
              <select
                className='resize-none bg-transparent rounded-xl text-xs border border-neutral-600 shadow-inner'
                name='selectValue'
                value={block.data.selectValue}
                onChange={handleChange}
              >
                <option value='GET'>GET</option>
                <option value='POST'>POST</option>
              </select>
            </label>
            <label className='flex gap-2 items-center'>
              Protocol:
              <select
                className='resize-none bg-transparent rounded-xl text-xs border border-neutral-600 shadow-inner'
                name='protocol'
                value={block.data.protocol}
                onChange={handleChange}
              >
                <option value='https'>HTTPS</option>
                <option value='http'>HTTP</option>
              </select>
            </label>
            <label className='flex gap-2 items-center'>
              <p className='shrink-0'>API URL:</p>
              <input
                className='w-full bg-transparent p-2 rounded-xl text-xs border border-neutral-600 shadow-inner'
                name='apiURL'
                value={block.data.apiURL ?? ''}
                onChange={handleChange}
              />
            </label>
            <KeyValueInputForm
              target='apiQueryKeyValueData'
              targetDisplayName='Query'
              block={block}
              blockIndex={index}
              tooltipText='<p>Add any URL parameters here.</p>
          <p>The final URL of the API request will look something like apiUrl?key1=value1&key2=value2</p>
          <p>Field values checked as private will not be visible to other users.</p>
          <p>Warning: Private fields will be sent as they appear, so references to other blocks will not work there.</p>'
              allowPrivateFields
            />
            <br />
            {block.data.selectValue === 'POST' && (
              <>
                <KeyValueInputForm
                  target='apiRequestBodyKeyValueData'
                  targetDisplayName='Request body'
                  tooltipText='<p>You can specify nested keys using dot notation, e.g. key1.key2</p>
              <p>Field values checked as private will not be visible to other users.</p>
              <p>Warning: Private fields will be sent as they appear, so references to other blocks will not work there.</p>'
                  block={block}
                  blockIndex={index}
                  allowPrivateFields
                />
                <br />
              </>
            )}
            <KeyValueInputForm
              target='apiRequestOptionsKeyValueData'
              targetDisplayName='Request options'
              tooltipText='<p>You can specify nested keys using dot notation, e.g. key1.key2</p>
          <p>Field values checked as private will not be visible to other users.</p>
          <p>Please ensure to mark sensitive data (for example API keys) as private.</p>
          <p>Warning: Private fields will be sent as they appear, so references to other blocks will not work there.</p>'
              block={block}
              blockIndex={index}
              allowPrivateFields
            />
            <br />
            <label>
              Response data path{' '}
              <MyToolTips
                content='<p>Enter the keys for the property of the response data you 
            want to return, or leave this field blank to return the whole response data.</p>
            <p>You can specify nested keys using dot notation, e.g. key1.key2</p>'
                tipID={'response-tooltip'}
                datatooltipplace='right'
              />
            </label>

            <AutocompleteTextarea
              className='w-full sm:w-1/2'
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              name='responseDataPath'
              value={block.data.responseDataPath ?? ''}
            />
            <br />
            <label className='flex items-center gap-2'>
              Credit charge per use (Maximum 2 credits)
              <input
                type='number'
                max='2'
                min='0'
                step='0.001'
                className='bg-transparent w-20 p-2 rounded-xl text-sm border border-neutral-600 shadow-inner'
                onChange={handleChange}
                name='creditCharge'
                value={block.data.creditCharge ?? ''}
              />
              <MyToolTips
                content='<p>Each month you will be reimbursed with $0.10 for each credit charged.</p>
                <p>Credits will only be taken from other users and only if the request succeeds.</p>'
                tipID={'response-tooltip'}
                datatooltipplace='right'
              />
            </label>

            <button
              onClick={onSendClick}
              className={testButtonStyles}
            >
              Send request
            </button>

            <br />
          </>
        )}

        <p>Response:</p>
        <div className='w-full'>
          <TextareaAutosize
            className='resize-none max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
            value={block.data.response}
            readOnly
          />
        </div>
      </div>
    </FuserLoader>
  );

  async function onSendClick() {
    if (!toolId) {
      return alert('Please save the tool before making a request.');
    }

    setIsLoading(true);

    try {
      const customApiResponse = await axios.post(
        `${backendURL}/custom-api-request`,
        {
          baseUrl: ensureFlattenedToString(
            replacePlaceholders(block.data.apiURL, blocks)
          ),
          httpVerb: block.data.selectValue,
          protocol: block.data.protocol,
          apiQueryKeyValues: keyValueDataToObject(
            block.data.apiQueryKeyValueData
          ),
          apiRequestBody: keyValueDataToObject(
            block.data.apiRequestBodyKeyValueData
          ),
          apiRequestOptions: keyValueDataToObject(
            block.data.apiRequestOptionsKeyValueData
          ),
          creditCharge: block.data.creditCharge,
          responseDataPath: ensureFlattenedToString(
            replacePlaceholders(block.data.responseDataPath, blocks)
          ),
          toolId,
          blockIndex: index,
        },
        { headers: { Authorization: authHeader } }
      );

      const { apiResponse, updatedCreditInDollars } = customApiResponse.data;

      block.data.response = apiResponse;

      if (updatedCreditInDollars) {
        updateCredits(updatedCreditInDollars * 10);
      }
    } catch (error) {
      console.log(error);
      setStillRunning(false);
      return;
    } finally {
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }

    if (runnerMode) {
      const collapseAfterRunning = [true, undefined].includes(
        block.collapseAfterRunning
      );
      setCollapsedBlocks((currentCollapsedBlocks: any) => [
        ...(collapseAfterRunning ? [block.id] : []),
        ...currentCollapsedBlocks,
      ]);
    }

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved API block at index: ${index}`,
    ]);

    setBlocks(
      (runnerMode ? updateAtIndexRun : updateAtIndex)(index, block, blocks)
    );
  }

  function keyValueDataToObject(keyValueData: any) {
    const object: any = {};
    keyValueLoop: for (const { key, value, isPrivate } of keyValueData) {
      if (!key) {
        continue;
      }
      const processedKey = ensureFlattenedToString(
        replacePlaceholders(key, blocks)
      );
      const processedValue = ensureFlattenedToString(
        replacePlaceholders(value, blocks)
      );

      const keyComponents = processedKey.split('.');
      let subObject = object;
      for (const keyComponent of keyComponents.slice(0, -1)) {
        if (typeof subObject !== 'object') {
          continue keyValueLoop;
        }
        if (subObject[keyComponent] === undefined) {
          subObject[keyComponent] = {};
        }
        subObject = subObject[keyComponent];
      }
      if (typeof subObject !== 'object') {
        continue keyValueLoop;
      }
      subObject[keyComponents.at(-1)] = processedValue;
    }
    return object;
  }
};

export default ApiBlock;
