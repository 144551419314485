import React, { useContext } from 'react';
import FuserContext from '../context/FuserContext';
import { FiPlus } from 'react-icons/fi';
import { iconStyle, testButtonStyles } from '../constants/styles';
import { updateAtIndex } from '../utils/array';
import AutocompleteTextarea from '../containers/FuserPage/AutocompleteTextarea';
import MyToolTips from './MyTooltip';

const menuButtonStyles =
  'bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-xs cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out w-10';

const KeyValueInputForm = ({
  target,
  targetDisplayName,
  tooltipText,
  block,
  blockIndex,
  allowPrivateFields,
}: any) => {
  const { runnerMode, blocks, setBlocks } = useContext(FuserContext);
  const updateBlocks = () =>
    setBlocks(updateAtIndex(blockIndex, block, blocks));
  return (
    <>
      <p>
        {targetDisplayName} keys/values{' '}
        {tooltipText && (
          <MyToolTips
            content={tooltipText}
            tipID={`${target}-tooltip`}
            datatooltipplace='right'
          />
        )}
      </p>
      {block.data[target]?.map(
        ({ userDisplayLabel, key, value, isPrivate }: any, index: number) => (
          <div
            key={index}
            className='bg-transparent w-full flex items-center justify-between'
          >
            <div className='w-full bg-transparent flex flex-col sm:flex-row gap-3 items-center justify-between p-2'>
              <label className='w-full sm:w-1/3 flex items-center gap-1'>
                Key:
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={blockIndex}
                  name={`${target}.${index}.key`}
                  value={key}
                  containerClassName='flex w-3/4 items-center'
                  onChange={(e: any) => {
                    block.data[target][index].key = e.target.value;
                    updateBlocks();
                  }}
                />
              </label>
              <label className='w-full sm:w-1/3 flex items-center gap-1'>
                Value:
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={blockIndex}
                  name={`${target}.${index}.value`}
                  value={value}
                  containerClassName='flex w-3/4 items-center'
                  onChange={(e: any) => {
                    block.data[target][index].value = e.target.value;
                    updateBlocks();
                  }}
                />
              </label>
              {allowPrivateFields && (
                <label className='flex items-center gap-1'>
                  Private
                  <input
                    type='checkbox'
                    checked={isPrivate}
                    onChange={(e: any) => {
                      block.data[target][index].isPrivate =
                        !block.data[target][index].isPrivate;
                      updateBlocks();
                    }}
                    />
                </label>
              )}
              {!runnerMode && (
                <button
                  className={`${testButtonStyles} border-black border-1 w-fit`}
                  onClick={() => {
                    block.data[target] = block.data[target].filter(
                      (_: any, i: number) => i !== index
                    );
                    updateBlocks();
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        )
      )}

      <button
        className={`${menuButtonStyles}`}
        onClick={() => {
          block.data[target].push({
            key: '',
            value: '',
          });
          updateBlocks();
        }}
      >
        <FiPlus className={`${iconStyle}`} />
      </button>
    </>
  );
};

export default KeyValueInputForm;
