import React, { useContext, useState } from "react";
import { BiRun } from "react-icons/bi";
import { FaPause, FaPlay } from "react-icons/fa";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import FuserContext from "../../context/FuserContext";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import Toggle from "../../components/Toggle";
import axios from "axios";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { iconStyle, menuButtonStyles } from "../../constants/styles";
import { backendURL } from "../../constants/environmental";
import { useNavigate } from "react-router-dom";
import { blockTypesExpandedByDefault } from "../../constants/blocks";

const FuserMainOptions = () => {
  const {
    toolId,
    blocks,
    setBlocks,
    setCollapsedBlocks,
    runnerMode,
    pauseRunnerMode,
    restartRunnerMode,
    isFullScreen,
    setIsFullScreen,
    contentRef,
    stillRunning,
    isOwner,
    resumeRunnerMode,
    runnerIndex,
    blockRefs,
    isSidebarOpen,
    toggleSidebar,
    minimalMode,
    setMinimalMode,
    restartQueued,
    setBlockScrollingIntoView,
    clearUnsavedResponses,
    toggleRunnerMode,
    buildModeBlocks,
    setBuildModeBlocks,
    setWaitingForClearUnsavedResponses,
    toolMetadata: { authorId, lastSavedRunnerIndex },
    updateToolMetadata,
    responseContexts,
    setResponseContexts,
    selectedResponseContext,
    setSelectedResponseContext,
    LoadBlock,
    blocksHaveLoaded
  } = useContext(FuserContext);

  // const currentBlockType = blocks?.[runnerIndex]?.type?.split('-')?.[1];

  // const currentBlockMessage =
  //   currentBlockType === 'question'
  //     ? 'Please answer the question'
  //     : currentBlockType === 'prompt'
  //       ? 'Talking to AI'
  //       : currentBlockType === 'image'
  //         ? 'Generating image'
  //         : currentBlockType === 'textToSpeech'
  //           ? 'Generating audio'
  //           : currentBlockType === 'download'
  //             ? 'Your download is ready'
  //             : currentBlockType === 'google'
  //               ? 'Searching Google'
  //               : currentBlockType === 'scraper'
  //                 ? 'Scraping websites'
  //                 : 'You are';

  // const isSmallScreen = window.matchMedia('(pointer: coarse)').matches;
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;
  const [confirmRestartShowing, setConfirmRestartShowing] = useState(false);
  
  const navigate = useNavigate();

  const createNewResponseContext = async () => {
    const contextName = prompt('Enter the name of your new response batch:');
    if (!contextName) return;

    try {
      const createContextResponse = await axios.post(
        `${backendURL}/admin/${userId}/${toolId}/responseContexts`,
        { contextName },
        { headers: { Authorization: authHeader } }
      );
      const newResponseContext = createContextResponse.data;
      setResponseContexts((previous: any) => [...previous, newResponseContext]);
      setSelectedResponseContext(newResponseContext);
      LoadBlock({ responseContextId: newResponseContext._id });
    } catch (error) {
      console.log('Error creating response context:', error);
    }
  };

  const editSelectedResponseContext = async () => {
    const newContextName = prompt('Enter a new name for your current response batch:');
    if (!newContextName) return;

    try {
      await axios.patch(
        `${backendURL}/admin/${userId}/${toolId}/responseContexts/${selectedResponseContext._id}`,
        { newContextName },
        { headers: { Authorization: authHeader } }
      );
      setResponseContexts((previous: any) => previous.map((responseContext: any) => (
        responseContext._id === selectedResponseContext._id ?
        { ...responseContext, name: newContextName } :
        responseContext
      )));
    } catch (error) {
      console.log('Error editing response context:', error);
    }
  };

  const deleteSelectedResponseContext = async () => { 
    const confirmed = confirm('Are you sure you want to delete these responses?');
    if (!confirmed) return;

    try {
      await axios.delete(
        `${backendURL}/admin/${userId}/${toolId}/responseContexts/${selectedResponseContext._id}`,
        { headers: { Authorization: authHeader } }
      );
      const newResponseContexts = responseContexts.filter((responseContext: any) => (
        responseContext._id !== selectedResponseContext._id
      ))

      setResponseContexts(newResponseContexts);

      if (newResponseContexts.length) {
        const firstResponseContext = newResponseContexts[0];
        setSelectedResponseContext(firstResponseContext);
        LoadBlock({ responseContextId: firstResponseContext._id });
      }
      else {
        LoadBlock();
      }
    } catch (error) {
      console.log('Error deleting response context:', error);
    }
  };
  
  return (
    <div
      className={`grid grid-cols-[auto_1fr] grid-flow-col 
        sm:grid-flow-row sm:grid-cols-[auto_1fr_auto] sm:grid-rows-1 ${
        runnerMode ? "grid-rows-2 justify-between" : "justify-end"
      } gap-2 items-center mt-2 p-2 py-4 dark:bg-neutral-800 rounded-lg shadow-xl text-xs`}
    >
      {!runnerMode ? <div></div> : (
        <div className="flex items-center gap-16">
          <label className="flex gap-3 w-fit items-center">
            <Toggle
              className="cursor-pointer"
              toggled={minimalMode}
              onChange={() => {
                const newMinimalMode = !minimalMode;
                setMinimalMode((mode: boolean) => !mode);
                // setCookie(
                //   '_auth_state',
                //   {
                //     ...cookies._auth_state,
                //     minimalMode: newMinimalMode ? 'true' : 'false',
                //   },
                //   { path: '/' }
                // );
                axios.patch(
                  `${backendURL}/user/details/${userId}`,
                  {
                    minimalMode: newMinimalMode,
                  },
                  {
                    headers: { Authorization: authHeader },
                  }
                );
              }}
            />
            <span>Minimal mode</span>
          </label>
          {!toolId || user?.loggedin === 'true' ?
            null :
            !blocksHaveLoaded ?
            <p className="text-lg">Loading...</p> :
            responseContexts?.length > 0 ? (
            <div className="flex gap-2 w-max">
              <label
              htmlFor="responseContextSelect"
              className="text-lg flex items-center gap-2"
              >
                Load responses:
                <select
                  name='responseContextSelect'
                  className="bg-transparent rounded-xl border border-2 border-neutral-700 shadow-inner"
                  value={selectedResponseContext?._id ?? responseContexts[0]?._id}
                  onChange={(e) => {
                    const newResponseContext = responseContexts.find(
                      ({ _id }: any) => _id === e.target.value
                    );
                    setSelectedResponseContext(newResponseContext);
                    LoadBlock({ responseContextId: newResponseContext._id });
                  }}
                >
                  {
                    responseContexts.map(({ name, _id }: any) => (
                      <option key={_id} value={_id}>{name}</option>
                    ))
                  }
                </select>
              </label>
              <button className="text-2xl" onClick={createNewResponseContext}>+</button>
              <button onClick={editSelectedResponseContext}>✎</button>
              <button className="text-xl" onClick={deleteSelectedResponseContext}>X</button>
            </div>
          ) : (
            <button
              className='text-lg px-2 rounded-xl border border-2 border-neutral-700'
              onClick={createNewResponseContext}
            >
              Create new response batch
            </button>
          )}
        </div>
      )}
      {runnerMode && Number.isInteger(runnerIndex) && runnerIndex >= 0 && (
        <p className="text-lg flex justify-center col-span-2 sm:col-span-1 sm:justify-self-end">
          {runnerIndex < blocks.length ? (
            <>
              {/* {currentBlockMessage + ' on '} */}
              <a
                className="cursor-pointer underline"
                onClick={() => {
                  blockRefs.current[runnerIndex].scrollIntoView({
                    block: "nearest",
                    behavior: "smooth",
                  });
                  setBlockScrollingIntoView(true);
                }}
              >
                You are on Block {runnerIndex}
              </a>{" "}
              / {blocks.length}
            </>
          ) : (
            "Finished running 🎉🥳🎊"
          )}
        </p>
      )}
      <div className="flex items-center justify-end gap-2">
        {runnerMode && confirmRestartShowing && (
          <div className="flex flex-col sm:flex-row items-center gap-0 sm:gap-2">
            <p className="text-center">Confirm restart</p>
            <div className="flex gap-2 justify-center">
              <button
                className={
                  menuButtonStyles +
                  " h-8 w-8 sm:h-10 sm:w-10 p-0 sm:p-1 text-2xl font-bold"
                }
                onClick={() => {
                  restartRunnerMode();
                  setConfirmRestartShowing(false);
                }}
              >
                ✔
              </button>
              <button
                className={
                  menuButtonStyles +
                  " h-8 w-8 sm:h-10 sm:w-10 text-center align-middle p-0 sm:p-1 text-lg"
                }
                onClick={() => {
                  setConfirmRestartShowing(false);
                }}
              >
                ❌
              </button>
            </div>
          </div>
        )}
        {runnerMode && !confirmRestartShowing && (
          <button
            className={menuButtonStyles + " flex items-center h-10"}
            onClick={() => setConfirmRestartShowing(true)}
          >
            {restartQueued ? (
              <div className="mb-1/2">Restarting...</div>
            ) : (
              <svg
                width="25"
                height="25"
                viewBox="0 0 70 70"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  data-name="layer1"
                  d="M3.307 22.023a3 3 0 0 1 4.17.784l2.476 3.622A27.067 27.067 0 0 1 36 6c14.557 0 26 12.036 26 26.584a26.395 26.395 0 0 1-45.066 18.678 3 3 0 1 1 4.244-4.242A20.395 20.395 0 0 0 56 32.584C56 21.344 47.248 12 36 12a21.045 21.045 0 0 0-20.257 16.059l4.314-3.968a3 3 0 0 1 4.062 4.418l-9.737 8.952c-.013.013-.03.02-.043.033-.067.06-.143.11-.215.163a2.751 2.751 0 0 1-.243.17c-.076.046-.159.082-.24.12a3.023 3.023 0 0 1-.279.123c-.08.03-.163.05-.246.071a3.045 3.045 0 0 1-.323.07c-.034.006-.065.017-.1.022-.051.006-.102-.002-.154.002-.063.004-.124.017-.187.017-.07 0-.141-.007-.212-.012l-.08-.004-.05-.003c-.06-.007-.118-.03-.178-.04a3.119 3.119 0 0 1-.388-.087c-.083-.027-.16-.064-.239-.097a2.899 2.899 0 0 1-.314-.146 2.753 2.753 0 0 1-.233-.151 2.807 2.807 0 0 1-.262-.2 2.857 2.857 0 0 1-.2-.19 3.013 3.013 0 0 1-.224-.262c-.03-.04-.069-.073-.097-.114L2.523 26.194a3.001 3.001 0 0 1 .784-4.17z"
                  fill="#202020"
                ></path>
              </svg>
            )}
          </button>
        )}
        {runnerMode &&
          !confirmRestartShowing &&
          (stillRunning || restartQueued ? (
            <button className={menuButtonStyles} onClick={pauseRunnerMode}>
              <FaPause className={iconStyle} />
            </button>
          ) : (
            <button
              className={
                menuButtonStyles + " flex items-center gap-1 animate-toms-glow"
              }
              onClick={resumeRunnerMode}
            >
              (resume)
              <FaPlay className={iconStyle} style={{ display: "inline" }} />
            </button>
          ))}
        {/*{!runnerMode && (
          <>
    
            <button
              className={menuButtonStyles}
              onClick={undoChanges}
            >
              <FaUndo className={iconStyle} />
            </button>
            <button
              className={menuButtonStyles}
              onClick={redoChanges}
            >
              <FaRedo className={iconStyle} />
            </button>
          </>
        )}*/}
        <button className={menuButtonStyles} onClick={toggleFullScreen}>
          {isFullScreen ? (
            <MdFullscreenExit className={iconStyle} />
          ) : (
            <MdFullscreen className={iconStyle} />
          )}
        </button>
        {
          //show runner mode toggle if the block has not been saved, ie no authorId
          (isOwner || !authorId) && (
            <button className={menuButtonStyles} onClick={onRunnerModeClick}>
              <BiRun className={iconStyle} />
            </button>
          )
        }
        {runnerMode && !isSidebarOpen && (
          <button
            className={menuButtonStyles + " hidden sm:block"}
            onClick={toggleSidebar}
          >
            <TbLayoutSidebarRightExpand className={iconStyle} />
          </button>
        )}
        {(isOwner || !authorId) && (
          <a
            href="/maker-help"
            target="_blank"
            style={{ color: "darkblue", textDecoration: "underline" }}
          >
            help
          </a>
        )}
      </div>
    </div>
  );

  function toggleFullScreen() {
    if (!document.fullscreenElement && contentRef.current) {
      contentRef.current.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  }

  function onRunnerModeClick() {
    // updates the url, not sure if this is needed as only want runner mode when the file loads in runner mode i.e. its a user
    const params = new URLSearchParams(location.search);
    navigate({ search: params.toString() });

    if (runnerMode) {
      params.delete("runner");
      toggleRunnerMode();
      setBlocks(buildModeBlocks);
      setCollapsedBlocks([]);
      updateToolMetadata({ lastSavedRunnerIndex: runnerIndex });
    } else {
      //console.log('onrunnermodeclick called');
      params.set("runner", "true");

      if (typeof lastSavedRunnerIndex === "number") {
        setCollapsedBlocks(
          blocks
            .filter((block: any, index: number) => {
              return (
                index > lastSavedRunnerIndex ||
                (block.collapseAfterRunning !== undefined
                  ? block.collapseAfterRunning
                  : blockTypesExpandedByDefault.includes(block.type)
                    ? false
                    : true)
              );
            })
            .map(({ id }: { id: string }) => id)
        );
      } else {
        // collapse all blocks except the first
        setCollapsedBlocks(blocks.slice(1).map(({ id }: { id: string }) => id));
      }

      // remove any blocks that have an unselected type
      const filterNonNullBlocks = (blocks: any) =>
        blocks.filter((block: any) => block.type !== "");
      setBlocks(filterNonNullBlocks);
      setBuildModeBlocks(filterNonNullBlocks(blocks));

      clearUnsavedResponses();
      setWaitingForClearUnsavedResponses(true);
      // runner mode is toggled in the next effect after all responses are cleared
    }
  }
};

export default FuserMainOptions;
