import { FC } from 'react';
import PromptBlock from '../components/Blocks/PromptBlock';
import QuestionBlock from '../components/Blocks/QuestionBlock';
import InfoBlock from '../components/Blocks/InfoBlock';
import ProcessingBlock from '../components/Blocks/ProcessingBlock';
import ReducingBlock from '../components/Blocks/ReducingBlock';
import ImageBlock from '../components/Blocks/ImageBlock';
import BlockProps from '../models/BlockProps';
import DownloadBlock from '../components/Blocks/DownloadBlock';
import ZapierBlock from '../components/Blocks/ZapierBlock';
import HTMLPreviewBlock from '../components/Blocks/HTMLPreviewBlock';
import EmbeddingsBlock from '../components/Blocks/EmbeddingsBlock';
import IfElseBlock from '../components/Blocks/IfElseBlock';
import ScraperBlock from '../components/Blocks/ScraperBlock';
import GoogleSearchBlock from '../components/Blocks/GoogleSearchBlock';
import TextToSpeechBlock from '../components/Blocks/TextToSpeechBlock';
import ChatBlock from '../components/Blocks/ChatBlock';
import JumpBlock from '../components/Blocks/JumpBlock';
import ApiBlock from '../components/Blocks/ApiBlock';
import CustomJavascriptBlock from '../components/Blocks/CustomJavascriptBlock';
import { defaultInputValues } from '../utils/prompt';
import { defaultLanguageCode, defaultLanguageTag, languageToGeolocationCodePair } from './location';
import BingSearchBlock from '../components/Blocks/BingSearchBlock';
import GoogleAdsBlock from '../components/Blocks/GoogleAdsBlock';

const defaultEmbeddingsFilterNumber: Record<string, number> = {
  'top-results': 1,
  'next-results': 1,
  'max-words': 100,
  'random-results': 10,
};

const MAX_PREVIEW_CHARS = 50;

const blockTypesExpandedByDefault = ['blockdiv-chat'];

const blockComponents: Record<
  string,
  { component: FC<BlockProps>; selectName: string }
> = {
  'blockdiv-prompt': {
    component: PromptBlock,
    selectName: 'Prompt',
  },
  'blockdiv-chat': {
    component: ChatBlock,
    selectName: 'Chat',
  },
  'blockdiv-question': {
    component: QuestionBlock,
    selectName: 'User Question',
  },
  'blockdiv-info': {
    component: InfoBlock,
    selectName: 'Info Block',
  },
  'blockdiv-processing': {
    component: ProcessingBlock,
    selectName: 'Processing Block',
  },
  'blockdiv-reducing': {
    component: ReducingBlock,
    selectName: 'Reducing Block',
  },
  'blockdiv-image': {
    component: ImageBlock,
    selectName: 'Image Block',
  },
  'blockdiv-download': {
    component: DownloadBlock,
    selectName: 'Download Block',
  },
  'blockdiv-api': {
    component: ApiBlock,
    selectName: 'API Block',
  },
  'blockdiv-zapier': {
    component: ZapierBlock,
    selectName: 'Zapier Block',
  },
  'blockdiv-htmlpreview': {
    component: HTMLPreviewBlock,
    selectName: 'HTML Preview Block',
  },
  'blockdiv-embeddings': {
    component: EmbeddingsBlock,
    selectName: 'Embeddings Block',
  },
  'blockdiv-if-else': {
    component: IfElseBlock,
    selectName: 'If-else block',
  },
  'blockdiv-jump': {
    component: JumpBlock,
    selectName: 'Jump block',
  },
  'blockdiv-scraper': {
    component: ScraperBlock,
    selectName: 'Scraper block',
  },
  'blockdiv-google': {
    component: GoogleSearchBlock,
    selectName: 'Google Search',
  },
  'blockdiv-google-ads': {
    component: GoogleAdsBlock,
    selectName: 'Google Ads',
  },
  'blockdiv-bing': {
    component: BingSearchBlock,
    selectName: 'Bing Search',
  },
  'blockdiv-textToSpeech': {
    component: TextToSpeechBlock,
    selectName: 'Text to Speech',
  },
  'blockdiv-customJavascript': {
    component: CustomJavascriptBlock,
    selectName: 'Custom Javascript',
  },
};

const initialBlockData: Record<string, object> = {
  'blockdiv-prompt': {
    gptModel: 'gpt4o',
    presencePenalty: 0.6,
    frequencyPenalty: 0.15,
    topP: 1,
    temperature: 0.9,
    maximumWordsInResponse: defaultInputValues.maximumWordsInResponse.turbo,
    editableOutput: false,
    showOutputToUser: true,
    reasoningEffort: 'medium',
  },
  'blockdiv-chat': {
    inputToProcess: '',
    gptModel: 'gpt4o',
    presencePenalty: 0.6,
    frequencyPenalty: 0.15,
    topP: 1,
    temperature: 0.9,
    maximumWordsInResponse: defaultInputValues.maximumWordsInResponse.turbo,
    editableOutput: false,
    showOutputToUser: true,
  },
  'blockdiv-question': {
    multipleChoiceAnswers: [],
    selectValue: 'text',
    checkedRadioValue: '',
    response: '',
  },
  'blockdiv-info': {
    editableOutput: false,
  },
  'blockdiv-processing': {
    inputToProcess: '',
    selectValue: 'numbered-list',
    checkedRadioValue: 'process',
    referenceModifier: 'increment',
    appendSecondOperand: '',
    customSeparator: '',
    customMatchPattern: '',
    customMatchFlags: '',
    newValue: '',
    changeAmountString: '1',
    cropAmountString: '1000',
    numberOfWordsToRemoveFromStart: '400',
    removeFirstMatchPattern: '',
    removeFirstMatchFlags: '',
    removeEmptyElements: false,
  },
  'blockdiv-reducing': {
    checkedRadioValue: 'one-level',
    selectValue: ',',
    removeInnerDuplicates: false,
    reduceSideways: false,
    customDelimiter: '',
  },
  'blockdiv-image': {
    model: 'dall-e-3',
    imageStyle: 'vivid',
    imageQuality: 'standard',
  },
  'blockdiv-download': {
    selectValue: 'txt',
    googleDocFormat: 'plain text',
    googleDocIds: [],
    giveOptionToPreview: false,
    creator: '',
  },
  'blockdiv-api': {
    selectValue: 'GET',
    protocol: 'https',
    apiQueryKeyValueData: [],
    apiRequestBodyKeyValueData: [],
    apiRequestOptionsKeyValueData: [],
    responseDataPath: '',
  },
  'blockdiv-embeddings': {
    multipleChoiceAnswers: [],
    multipleChoiceOutputs: [],
    checkedRadioValue: 'no',
    selectValue: 'top-results',
    filterNumber: defaultEmbeddingsFilterNumber['top-results'],
    startResultIndex: 0,
    inputToProcess: '',
    model: 'text-embedding-3-small',
    useDefaultEmbedding: false,
    defaultEmbeddingThreshold: 0.3,
    defaultEmbedding: '',
    optionsSource: 'define-options'
  },
  'blockdiv-if-else': {
    selectValue: 'equals',
  },
  'blockdiv-scraper': {
    ignoreFailedScrapes: false,
  },
  'blockdiv-google': {
    numberOfResults: 10,
    multipleChoiceAnswers: ['link', 'snippet', 'title'],
    searchLocationCode: defaultLanguageCode,
  },
  'blockdiv-google-ads': {
    entity: 'campaign'
  },
  'blockdiv-bing': {
    numberOfResults: 10,
    multipleChoiceAnswers: ['url', 'snippet', 'name'],
    marketCode: defaultLanguageTag,
    recencyOfResults: 30,
  },
  'blockdiv-textToSpeech': {
    voice: 'alloy',
    speed: 1,
    responseFormat: 'mp3',
  },
};

const blocktypesThatDontRunAutomatically = [
  "blockdiv-question",
  "blockdiv-download",
  "blockdiv-bing"
];

const blockReferenceRegex = /(<\d+:(in|out)put>)|(@\w+)/g;

const dangerousBlockTypes = ['htmlpreview', 'customJavascript'];

const blockDataKeysHoldingReferences = [
  'inputToProcess',
  'secondInputToProcess',
  'customSeparator',
  'appendSecondOperand',
  'message',
  'startResultIndex',
];

const blockDataKeysHoldingBlockNumbers = [
  'ifInput',
  'elseInput',
  'indexOfBlockToJumpTo',
  'chatBlockNumber',
];

export {
  blockDataKeysHoldingBlockNumbers,
  blockDataKeysHoldingReferences,
  dangerousBlockTypes,
  blockReferenceRegex,
  blockComponents,
  initialBlockData,
  blockTypesExpandedByDefault,
  MAX_PREVIEW_CHARS,
  defaultEmbeddingsFilterNumber,
  blocktypesThatDontRunAutomatically,
};
