import React, { useContext, useState } from 'react';
import FuserContext from '../../context/FuserContext';

function LoadResponsesSection() {
  const { LoadBlock } = useContext(FuserContext);

  const [responsesFromUserWithId, setResponsesFromUserWithId] = useState<any>();

  return (
    <div className='flex gap-2 items-center'>
      <input
        className='py-1 px-2 border border-1 border-black'
        value={responsesFromUserWithId}
        onChange={({ target: { value } }) => setResponsesFromUserWithId(value)}
        placeholder='Enter User ID'
      />
      <button
        className='border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
        onClick={() => LoadBlock({responsesFromUserWithId})}
      >
        Load responses
      </button>
    </div>
  );
}

export default LoadResponsesSection;
