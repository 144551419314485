import React, { FC, useState, useEffect, useContext } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import Block from '../../models/Block';
import BlockProps from '../../models/BlockProps';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { testButtonStyles } from '../../constants/styles';
import AutosizeTextarea from '../../containers/FuserPage/AutosizeTextarea';

const { isInteger } = Number;
const { isArray } = Array;

const JumpBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  block,
  index,
  handleChange,
}) => {
  const {
    blocks,
    blockStyles,
    runnerMode,
    setBlocks,
    setActivityLog,
  } = useContext(FuserContext);

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { response, indexOfBlockToJumpTo } = block.data;

  return (
    <FuserLoader
      name='Jump Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          <div>Jump block</div>

          {response && isArray(response) && response.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: `Going to block ${response[0]}`,
              }}
            />
          )}
        </>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <div className='w-full flex gap-4 items-center'>
            <label className='w-max flex items-center'>Jump to block number: </label>
            <AutosizeTextarea
              className='w-20 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
              name='indexOfBlockToJumpTo'
              value={indexOfBlockToJumpTo ?? ''}
              onChange={onNumberInputChange}
              block={block}
            />
          </div>

          <button
            onClick={onTestClick}
            className={testButtonStyles}
          >
            Test
          </button>

          <p>{errorMessage}</p>

          {response && isArray(response) && response.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: errorMessage
                  ? ''
                  : `Block ${response[0]} will run next in runner mode`,
              }}
            />
          )}
        </div>
      )}
    </FuserLoader>
  );

  async function onTestClick() {
    setIsLoading(true);
    setErrorMessage('');

    let indexOfBlockToJumpTo = +block.data.indexOfBlockToJumpTo;

    if (!isValidBlockNumber(indexOfBlockToJumpTo)) {
      setErrorMessage(
        'Please enter a valid block number (made from digits 0-9, no more than the largest block number, not equal to this block number), otherwise the next block will run by default.'
      );
      // go to the next block if indexOfBlockToJumpTo is invalid
      indexOfBlockToJumpTo = index + 1;
    } else {
      setErrorMessage('');
    }

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved jump block at index: ${index}`,
    ]);

    const blockUpdater = (blocks: Block[]) => {
      const newBlocks = [...blocks];

      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: runnerMode,
        data: {
          ...newBlocks[index].data,
          type: 'jump',
          response: [indexOfBlockToJumpTo.toString()],
          indexOfBlockToJumpTo,
        },
      };

      return newBlocks;
    };

    if (!runnerMode) {
      setBlocks(blockUpdater);
    } else {
      return blockUpdater;
    }
  }

  function isValidBlockNumber(input: any) {
    return (
      isInteger(input) && input >= 0 && input < blocks.length && input !== index
    );
  }

  function onNumberInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.validity.valid) {
      setErrorMessage('');
      handleChange(e);
    }
  }
};

export default JumpBlock;
