import { createContext } from 'react';

interface PageSearchContextProps {
  setSearchTerm: any;
  blockSortingMethod: any;
  setBlockSortingMethod: any;
  freePaid: any;
  setFreePaid: any;
  setSelectedCategoryId: any;
  categories: any;
  rerenderCategoriesList: any;
  hideCategoriesMenuIfMobile: any;
}

const PageSearchContext = createContext<PageSearchContextProps>({
  hideCategoriesMenuIfMobile: null,
  rerenderCategoriesList: null,
  categories: null,
  setSearchTerm: null,
  blockSortingMethod: null,
  setBlockSortingMethod: null,
  freePaid: null,
  setFreePaid: null,
  setSelectedCategoryId: null,
});

export default PageSearchContext;
