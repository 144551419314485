import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { nftsImgs } from "../../constants/fakeData";
import { Helmet } from "react-helmet";
import FuserSidebar from "../FuserPage/FuserSidebar";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import Avatar from "../../shared/Avatar/Avatar";
import LikeButton2 from "../../components/LikeButton2";
import CommentsDisplay from "../../components/CommentsDisplay";
import { blockComponents } from "../../constants/blocks";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { iconStyle, menuButtonStyles } from "../../constants/styles";
import { backendURL } from "../../constants/environmental";
import { prettyPrintMultidimensionalStringArray } from "../../utils/array";
import { truncateAfter } from "../../utils/string";
import { useCredit } from "../../context/CreditContext";

const SharedBlock: FC<any> = () => {
  const authHeader = useAuthHeader()();

  const location = useLocation();
  const blockId = location.pathname
    .split("/")
    // filter empty parts in case of trailing slash
    .filter((pathPart) => pathPart !== "")
    .at(-1);

  const user = useAuthUser();
  const userId = user()?.id;

  const [blockJSX, setBlockJSX] = useState<any>();
  const [isPreview, setIsPreview] = useState<any>();
  const [toolData, setToolData] = useState<any>();
  const [sharerData, setSharerData] = useState<any>();
  const [shareMetadata, setShareMetadata] = useState<any>();
  const [usersWhoLikedBlock, setUsersWhoLikedBlock] = useState<any>();
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [purchaseMessage, setPurchaseMessage] = useState<string>("");

  const currentUserHasLikedBlock = usersWhoLikedBlock?.some(
    ({ _id }: any) => _id === userId
  );

  const loadBlock = async () => {
    const getSharedBlockResponse = await axios.get(
      `${backendURL}/blocks/shared/${blockId}`,
      { headers: { Authorization: authHeader } }
    );

    const { isPreview, sharedBlockData } = getSharedBlockResponse.data;
    
    setIsPreview(isPreview);

    const block = {
      id: "",
      type: sharedBlockData.blockType,
      data: {
        response: sharedBlockData.response,
        inputToProcess: sharedBlockData.inputToProcess,
        blockHeading: sharedBlockData.blockHeading,
      },
    };
    setSharerData({
      id: sharedBlockData.sharer._id,
      name: sharedBlockData.sharer.name,
      profileImage: sharedBlockData.sharer.profileImage,
    });
    setToolData({
      id: sharedBlockData.tool?._id,
      title: sharedBlockData.tool?.name,
      description: sharedBlockData.tool?.description,
      isPublished: sharedBlockData.tool?.isPublished,
      authorCoverPhoto: sharedBlockData.tool?.authorId.profileImage,
      authorId: sharedBlockData.tool?.authorId._id,
      authorName: sharedBlockData.tool?.authorId.name,
      customUrlSlug: sharedBlockData.tool?.customUrlSlug,
      inputToProcess: sharedBlockData.inputToProcess,
      response: sharedBlockData.response,
      imageURLs: sharedBlockData.imageURLs,
      type: sharedBlockData.blockType,
    });
    setShareMetadata({
      priceInCredits: sharedBlockData.priceInCredits,
      description: sharedBlockData.description,
      viewCount: sharedBlockData.viewCount,
      uploadError: sharedBlockData.uploadError,
      customTitle: sharedBlockData.customTitle,
      linkToProfile: sharedBlockData.linkToProfile,
    });
    const BlockComponent =
      blockComponents[`blockdiv-${sharedBlockData.blockType}`].component;
    const { response, imageURLs } = sharedBlockData;
    // console.log(sharedBlockData);
    setBlockJSX(
      <BlockComponent
        block={block}
        index={0}
        // if the images have not finished uploading to cloudinary use the links provided by openAI in response
        imageURLs={response}
        sharedImageURLs={imageURLs}
        toolId={sharedBlockData.tool?._id}
        stillRunning={false}
        isLoading={false}
        resultHtml={(response
          ? truncateAfter(
              1000,
              Array.isArray(response)
                ? prettyPrintMultidimensionalStringArray(response)
                : response
            )
          : ""
        ).replace(
          /<(\/?)(script|style)>/g,
          (match, closingSlash, tagName) =>
            `&lt;${closingSlash}${tagName}&gt;`
        )}
        tool_id={sharedBlockData.tool?._id}
        collapsed={false}
        isShared={true}
        sharedBy={sharedBlockData.sharer.name}
        updateBlockData={() => {}}
        handleChange={(e) => {}}
        setIsLoading={() => {}}
        updateBlocks={() => {}}
      />
    );
  };

  const loadLikes = async () => {
    const getUsersWhoLikedBlockResponse = await axios.get(
      `${backendURL}/blocks/shared/${blockId}/likes`
    );

    const usersWhoLiked =
      getUsersWhoLikedBlockResponse.data.usersWhoLiked;
    setUsersWhoLikedBlock(
      usersWhoLiked.some(({ _id }: any) => _id === userId)
        ? [
            // put current user at the top
            { _id: userId, name: "You" },
            ...usersWhoLiked.filter(({ _id }: any) => _id !== userId),
          ]
        : usersWhoLiked
    );
  };

  const loadAll = async () => {
    setStatusMessage("");
    setPurchaseMessage('');

    try {
      await Promise.all([loadBlock(), loadLikes()]);
    }
    catch (error) {
      console.log("Error loading blocks", error);
      setStatusMessage("Error loading shared block");
    }
  }
  
  useEffect(() => {
    loadAll();
  }, []);

  const toggleLike = async () => {
    if (user()?.trialAccount) {
      return alert("Please register your account to like this block.");
    }
    try {
      const toggleResult = await axios.post(
        `${backendURL}/blocks/shared/${blockId}/like`,
        { liked: !currentUserHasLikedBlock },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      // console.log(toggleResult);
      setUsersWhoLikedBlock((users: any) =>
        toggleResult.data.liked
          ? [
              { _id: userId, name: "You" },
              // filter in case the user spam clicks and two consecutive responses with liked:true arrive
              ...users.filter(({ name }: any) => name !== "You"),
            ]
          : users.filter(({ _id }: any) => _id !== userId)
      );
    } catch (error) {
      const status = error?.response.status;
      if (status === 401) {
        return setStatusMessage("Please log in to like this block");
      }
      if (status === 403) {
        return setStatusMessage("Please purchase this block to add a like");
      }
      console.log("error toggling like:", error);
    }
  };

  // const blockClasses = `border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800 grow`;

  const navigate = useNavigate();

  const goToToolDetailsPage = () => {
    window.location.href = `/fusion/${toolData?.customUrlSlug ?? toolData?.id}`;
  };

  let id, otherFuserSidebarProps;
  if (toolData) {
    ({ id, ...otherFuserSidebarProps } = toolData);
  }

  const setSharedBlockVisibilityOnProfile = (visible: boolean) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { linkToProfile: visible },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setShareMetadata((previous: any) => ({
          ...previous,
          linkToProfile: visible,
        }));
        // console.log(shareMetadata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [newCustomTitle, setNewCustomTitle] = useState<string>("");
  const [titleBeingEdited, setTitleBeingEdited] = useState<boolean>(false);

  const [newDescription, setNewDescription] = useState<string>("");
  const [descriptionBeingEdited, setDescriptionBeingEdited] = useState<boolean>(false);

  const [newPrice, setNewPrice] = useState<string>("");
  const [priceBeingEdited, setPriceBeingEdited] = useState<boolean>(false);

  const [deleteConfirmationShowing, setDeleteConfirmationShowing] =
    useState<boolean>(false);

  const editSharedBlockTitle = (title: string) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { customTitle: title },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setTitleBeingEdited(false);
        setShareMetadata((previous: any) => ({
          ...previous,
          customTitle: title,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNewCustomTitle("");
    }
  };

  const editSharedBlockDescription = (description: string) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { description },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setDescriptionBeingEdited(false);
        setShareMetadata((previous: any) => ({
          ...previous,
          description,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNewDescription("");
    }
  };

  const editSharedBlockPrice = (priceInCredits: string) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { priceInCredits },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setPriceBeingEdited(false);
        setShareMetadata((previous: any) => ({
          ...previous,
          priceInCredits: +priceInCredits,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNewPrice("");
    }
  };

  const deleteSharedBlock = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/shared/${blockId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        return navigate(`/profile/${userId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const { updateCredits } = useCredit();

  const purchaseSharedBlock = async () => {
    try {
      const purchaseResponse = await axios.post(
        `${backendURL}/blocks/shared/${blockId}/purchase`,
        {},
        { headers: { Authorization: authHeader } }
      );

      updateCredits(purchaseResponse.data.newCredits);
      await loadAll();
      setStatusMessage('Purchase successful!')
    }
    catch (error) {
      if (error?.response.status === 401) {
        return setPurchaseMessage('Please log in to purchase this block.');
      }
      const errorMessage = (
        error?.response.data.error ?? 'Error purchasing shared block'
      );
      setPurchaseMessage(errorMessage);
    }
  };

  const isSharer = userId && userId === sharerData?.id;

  return (
    <div
      className=" bg-white dark:bg-neutral-900 lg:mb-12"
      data-nc-id="BuilderPage"
    >
      <Helmet>
        <title>Shared Block || Skillfusion.ai</title>
        <link
          rel="canonical"
          href={`https://skillfusion.ai/sharedBlock/${blockId}`}
        />
      </Helmet>

      <div className="lg:flex runner-height" id="main-content">
        <div
          className="flex-grow flex-col pb-4 px-2 w-full gap-2"
          id="blocks-container"
        >
          <div className="w-full dark:bg-neutral-800 mt-2 p-2 py-4 flex items-center justify-end rounded-lg shadow-xl text-lg flex gap-2">
            {userId === sharerData?.id && toolData?.type === "image"
              ? !toolData?.imageURLs
                ? "Upload in progress, this may take a few minutes. Please refresh the page to check the current status."
                : toolData?.imageURLs?.length !== toolData?.response?.length ||
                    shareMetadata.uploadError
                  ? "Sorry, some images failed to upload, the images will only display for about 20 minutes from the time when they were generated before they will be taken down from OpenAI's servers"
                  : `Image${
                      toolData?.imageURLs?.length !== 1 ? "s" : ""
                    } successfully uploaded!`
              : ""}
            {!isSidebarOpen && (
              <button
                className={menuButtonStyles + " hidden lg:block"}
                onClick={toggleSidebar}
              >
                <TbLayoutSidebarRightExpand className={iconStyle} />
              </button>
            )}
          </div>
          <div className="blocks-height px-4 h-full overflow-y-scroll bg-neutral-100 dark:bg-neutral-800 shadow-3xl rounded-xl mt-4 lg:pb-96">
            {shareMetadata?.customTitle && (
              <div className="mb-3">
                {titleBeingEdited ? (
                  <div className="flex items-center gap-2">
                    <input
                      className="border-2 border-black rounded-lg px-2"
                      value={newCustomTitle}
                      onChange={({ target: { value } }) =>
                        setNewCustomTitle(value)
                      }
                    />
                    <button
                      className="border border-black rounded-lg px-2"
                      onClick={editSharedBlockTitle(newCustomTitle)}
                    >
                      Save new title
                    </button>
                    <button
                      className="border border-black rounded-lg px-2"
                      onClick={() => {
                        setNewCustomTitle("");
                        setTitleBeingEdited(false);
                      }}
                    >
                      Discard changes
                    </button>
                  </div>
                ) : (
                  <>
                    <h2 className="font-bold text-xl">
                      {shareMetadata.customTitle}
                    </h2>
                    {isSharer && (
                      <button
                        className="border border-black rounded-lg px-2"
                        onClick={() => {
                          setNewCustomTitle(shareMetadata?.customTitle);
                          setTitleBeingEdited(true);
                        }}
                      >
                        Edit title
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {shareMetadata?.description && (
              <div className="mb-3">
                <h1 className="text-lg font-bold">Description</h1>
                {descriptionBeingEdited ? (
                  <div className="">
                    <textarea
                      className="w-1/2 h-48 rounded-lg px-2"
                      value={newDescription}
                      onChange={({ target: { value } }) =>
                        setNewDescription(value)
                      }
                    />
                    <div className="flex items-center gap-2">
                      <button
                        className="border border-black rounded-lg px-2"
                        onClick={editSharedBlockDescription(newDescription)}
                      >
                        Save new description
                      </button>
                      <button
                        className="border border-black rounded-lg px-2"
                        onClick={() => {
                          setNewDescription("");
                          setDescriptionBeingEdited(false);
                        }}
                      >
                        Discard changes
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <pre>{shareMetadata.description}</pre>
                    {isSharer && (
                      <button
                        className="border border-black rounded-lg px-2"
                        onClick={() => {
                          setNewDescription(shareMetadata?.description);
                          setDescriptionBeingEdited(true);
                        }}
                      >
                        Edit description
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {isPreview && <h2 className="font-bold">Preview:</h2>}
            {toolData && (
              <div className="border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800">
                {blockJSX}
              </div>
            )}
            {((shareMetadata?.priceInCredits && isPreview) || isSharer) && (
              <div className="flex items-center gap-4 mb-4">
                {!priceBeingEdited && (
                  <p className="font-bold">
                    Price: {Number(shareMetadata.priceInCredits.toFixed(2))} credits (${Number((shareMetadata.priceInCredits / 10).toFixed(2))})
                  </p>
                )}
                {isPreview ? (
                  <>
                    <button
                      className="bg-gradient-to-b from-blue-300 to-purple-400 rounded-full p-2 font-semibold text-slate-800"
                      onClick={purchaseSharedBlock}
                    >
                      Purchase Shared Block
                    </button>
                    <p className="text-red-700 font-semibold">{purchaseMessage}</p>
                  </>
                ) : (
                  <>
                    {priceBeingEdited ? (
                      <div className="flex items-center gap-2">
                        <input
                          className="w-24 border-2 border-black rounded-lg px-2"
                          value={newPrice}
                          onChange={({ target: { value } }) =>
                            setNewPrice(value)
                          }
                        />
                        <span>credits</span>
                        <button
                          className="border border-black rounded-lg px-2"
                          onClick={editSharedBlockPrice(newPrice)}
                        >
                          Save new price
                        </button>
                        <button
                          className="border border-black rounded-lg px-2"
                          onClick={() => {
                            setNewPrice("");
                            setPriceBeingEdited(false);
                          }}
                        >
                          Discard changes
                        </button>
                      </div>
                    ) : (
                      <button
                        className="border border-black rounded-lg px-2"
                        onClick={() => {
                          setNewPrice(shareMetadata?.priceInCredits);
                          setPriceBeingEdited(true);
                        }}
                      >
                        Edit price
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {isSharer && (
              <div className="flex gap-2 items-center">
                <label>Visible on profile</label>
                <input
                  type="checkbox"
                  checked={shareMetadata?.linkToProfile}
                  onChange={setSharedBlockVisibilityOnProfile(
                    !shareMetadata?.linkToProfile
                  )}
                />
                {deleteConfirmationShowing ? (
                  <>
                    <span>Confirm unshare:</span>
                    <button
                      className="border border-black rounded-lg px-2"
                      onClick={deleteSharedBlock}
                    >
                      Confirm
                    </button>
                    <button
                      className="border border-black rounded-lg px-2"
                      onClick={() => setDeleteConfirmationShowing(false)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="border border-black rounded-lg px-2"
                    onClick={() => setDeleteConfirmationShowing(true)}
                  >
                    Unshare
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-wrap justify-around items-center gap-x-16 gap-y-8">
              {sharerData?.name && (
                <div className="flex items-center ">
                  <Avatar
                    imageUrl={`${sharerData?.profileImage || nftsImgs[3]}`}
                    sizeClass="h-9 w-9"
                    radius="rounded-full"
                  />
                  <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                    <span className="text-sm">Shared by</span>
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                      <span>
                        <Link to={`/profile/${sharerData?.id}`}>
                          <p>{sharerData?.name} </p>
                          <p className="underline">Visit Profile</p>
                        </Link>
                      </span>
                    </span>
                  </span>
                </div>
              )}
              {toolData &&
                (toolData?.isPublished ? (
                  <p className="text-gray-500 text-center">
                    Generated by:{" "}
                    <a
                      className="hover:cursor-pointer text-black underline"
                      onClick={goToToolDetailsPage}
                    >
                      {toolData.title}
                    </a>
                  </p>
                ) : (
                  <p>{/*This tool has not been published yet*/}</p>
                ))}
              {shareMetadata && <p>Views: {shareMetadata.viewCount ?? 0}</p>}
              {toolData && (
                <LikeButton2
                  handleLikeClick={toggleLike}
                  liked={currentUserHasLikedBlock ?? false}
                  usersWhoLiked={usersWhoLikedBlock ?? []}
                  id={`like-${blockId}`}
                />
              )}
              {statusMessage && <p>{statusMessage}</p>}
            </div>
            {toolData && (
              <CommentsDisplay
                targetType="shared-block"
                targetId={blockId}
                isOwner={false}
                noReplies={true}
                readOnly={isPreview}
              />
            )}
          </div>
        </div>
        {isSidebarOpen && (
          <FuserSidebar
            isForSharedBlock={true}
            toolId={toolData?.id}
            toggleSidebar={toggleSidebar}
            {...otherFuserSidebarProps}
          />
        )}
      </div>
    </div>
  );
};

export default SharedBlock;
