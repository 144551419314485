import React, { FC, useState, useEffect } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import MainNav2Logged from "./MainNav2Logged";
import { useCredit } from '../../context/CreditContext';
import PagePay from '../../containers/PagePay/PagePay';
import PageSignUp from '../../containers/PageSignUp/PageSignUp';
import { MdClose } from 'react-icons/md';
import ModalContainer from '../ModalContainer';
import SignupModalSequence from '../SignupModalSequence';
import { useCookies } from 'react-cookie';
import { backendURL } from '../../constants/environmental';
import axios from 'axios';
import SplitTest from "../../containers/SplitTest";

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const user = useAuthUser()();
  const location = useLocation();
  const urlPath = location.pathname.slice(1); // remove the initial forward slash
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPayPage, setShowPayPage] = useState<boolean>(false);
  const [showSignupModalSequence, setShowSignupModalSequence] =
    useState<boolean>(false);

  const { credit } = useCredit();

  const userId = user?.id;
  const trialAccount = user?.trialAccount;

  const [freeCredits, setFreeCredits] = useState();

  useEffect(() => {
    axios
      .get(`${backendURL}/site/freeCredits`)
      .then(({ data }) => setFreeCredits(data));
  }, []);

  const [{
    accountCreatedWithNoFreeCreditsAndNeverBoughtCredits, l_id, accountId
  }] = useCookies([
    'accountCreatedWithNoFreeCreditsAndNeverBoughtCredits',
    'l_id',
    'accountId'
  ]);
  
  const showFreeCreditsInfoBanner = freeCredits !== undefined &&
    !userId &&
    !l_id &&
    !accountId;

  const showRegisterOrBuyCreditsBanner =
    credit !== undefined &&
    credit <= 0 &&
    userId &&
    !(trialAccount ? ['signup', 'login'] : 'buy-credits').includes(urlPath);

  // const { setReferAFriendModalOpen } = useContext(HeaderContext);

  return (
    <div className="nc-HeaderLogged relative w-full z-40 ">
      <MainNav2Logged />
      {showFreeCreditsInfoBanner && (
        <p className='text-xl font-black text-indigo-800 text-center px-4'>Register now to get {freeCredits} free credits</p> // , worth ${(freeCredits/10).toFixed(2)}
      )}
      {showRegisterOrBuyCreditsBanner && (
        <div className='flex flex-col md:flex-row gap-2 items-center justify-center'>
          {trialAccount ? (
            <div>
              <h1 className='text-center text-2xl font-black'>
                It looks like you are enjoying Skillfusion!
              </h1>
              <h1 className='text-center text-2xl font-black'>
                Register an account to continue and get some more free credits.
              </h1>
            </div>
          ) : (
            accountCreatedWithNoFreeCreditsAndNeverBoughtCredits ?
            <h2 className='text-center text-lg font-black'>
              Please buy credits to use tools
            </h2> :
            <h1 className='text-center text-2xl font-black'>
              <SplitTest
                    testKey="credit-cta-test"
                    variants={["enjoying", "loss", "roll"]}
                    eventName="buy_credits_cta_shown"
                  >
                  {(variant: any) => 
                  {
                    if (variant === "enjoying") return <h1 className='text-center text-2xl font-black'>It looks like you are enjoying Skillfusion! Buy more credits to continue.</h1>
                    if (variant === "loss") return <h1 className='text-center text-2xl font-black'>Top up now to maintain access to over 200 AI Tools</h1>
                    if (variant === "roll") return <h1 className='text-center text-2xl font-black'>You’re on a roll! Top up now to continue.</h1>
                  }
                
                  }
                  </SplitTest>
              
            </h1> 
          )}
          {trialAccount ? (
            <button
              onClick={() => {
                setShowModal(true);
                setShowSignupModalSequence(true);
              }}
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
            >
              Register
            </button>
          ) : (
            <button
              onClick={() => {
                navigate('/buy-credits');
                //setShowModal(true);
                //setShowPayPage(true);
              }}
              className={`font-black border-black border-2 p-1 px-2 rounded-xl text-align-center ${
                accountCreatedWithNoFreeCreditsAndNeverBoughtCredits ? 'text-xl' : 'text-2xl'
              }`}
            >
              {
                accountCreatedWithNoFreeCreditsAndNeverBoughtCredits ? 
                "buy credits" : 
                "buy more credits"
              }
            </button>
          )}
          {/*<h1 className='text-center text-3xl font-black'>or</h1>
            <button
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
              onClick={() => setReferAFriendModalOpen(true)}
            >
              Refer a friend
            </button>*/}
        </div>
      )}
      {showModal && (
        <div className='share-modal fixed inset-0 flex justify-center'>
          <div className='absolute inset-0 bg-black opacity-50 z-10'></div>
          <div className='relative z-20'>
            <div className='m-auto flex items-center justify-center p-4'>
              <div className='bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20'>
                <div className='flex flex-col gap-4'>
                  <div>
                    <button
                      className='sticky left-0 top-0'
                      onClick={() => {
                        setShowModal(false);
                        setShowSignupModalSequence(false);
                      }}
                    >
                      <MdClose />
                    </button>
                  </div>
                  <div
                    className={`${
                      showPayPage ? 'p-x-8' : 'flex p-8'
                    } justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]`}
                  >
                    {showSignupModalSequence && <SignupModalSequence />}
                    {showPayPage && (
                      <PagePay
                        inModal={true}
                        onPayment={() => {
                          setShowPayPage(false);
                          setShowModal(false);
                        }}
                      />
                    )}
                  </div>
                  <div className='flex gap-2 items-center'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
