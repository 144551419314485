import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

export interface SplitTestProps {
  /** A unique key for this test (used as the cookie name) */
  testKey: string;
  /** An array of variant identifiers (e.g. ["html", "message"]) */
  variants: string[];
  /** The event name to send to ReactGA */
  eventName: string;
  /** Optional cookie options (e.g. path, expires) */
  cookieOptions?: CookieSetOptions;
  /**
   * When true, the component will wait for an existing cookie rather than generate a new variant.
   * This is useful if you are using the same test twice on a page and want only one instance to set the cookie.
   */
  waitForCookie?: boolean;
  /**
   * Either a function that receives the chosen variant and returns ReactNode,
   * or static ReactNode.
   */
  children: ((variant: string) => React.ReactNode) | React.ReactNode;
}

const SplitTest: React.FC<SplitTestProps> = ({
  testKey,
  variants,
  eventName,
  cookieOptions,
  waitForCookie = false,
  children,
}) => {
  const [cookies, setCookie] = useCookies([testKey]);
  const [variant, setVariant] = useState<string | null>(null);

  useEffect(() => {
    if (waitForCookie) {
      // If waiting for the cookie, do nothing until the cookie is set.
      if (cookies[testKey]) {
        const savedVariant = cookies[testKey] as string;
        setVariant(savedVariant);

        // don't need to record it in GA4 again
        // ReactGA.event(eventName, {
        //   testName: testKey,
        //   variant: savedVariant,
        // });

      }
      // If the cookie isn't there, simply wait.
    } else {
      // Standard behavior: if no cookie exists, generate one.
      let savedVariant = cookies[testKey] as string | undefined;
      if (!savedVariant) {
        const randomIndex = Math.floor(Math.random() * variants.length);
        savedVariant = variants[randomIndex];
        const options: CookieSetOptions =
          cookieOptions || {
            path: "/",
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          };
        setCookie(testKey, savedVariant, options);
      }
      setVariant(savedVariant);
      const fullEventName = `${eventName}_${savedVariant}`; // using eventName_saveVariant so I don't need dimensions
      ReactGA.event(fullEventName, {
        testName: testKey,
        variant: savedVariant,
      });
    }
    // The effect runs when cookies change—so if one instance sets the cookie,
    // any other waiting instance will update accordingly.
  }, [cookies, waitForCookie, testKey, variants, eventName, setCookie, cookieOptions]);

  if (variant === null) return null;

  return (
    <>
      {typeof children === "function"
        ? (children as (variant: string) => React.ReactNode)(variant)
        : children}
    </>
  );
};

export default SplitTest;
