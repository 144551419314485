import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import missingpic from "../images/missing.jpg";
import { Fusion } from "../../TypeScript/Types";
import PageSearchContext from "../context/PageSearchContext";
import LazyLoad from 'react-lazyload';

export interface CardNFT2Props {
  className?: string;
  isLiked?: boolean;
  fusion?: Fusion;
}

const trimDescriptionAndRemoveSkillfusionLinks = (
  desc: string,
  maxLength: number
) => {
  if (typeof desc !== "string") {
    // Return a default string or keep it empty if desc is not valid
    return "";
  }

  if (desc.length > maxLength) {
    // Return the trimmed description with an ellipsis
    return `${desc.substring(0, maxLength)} ...`; // replaced the following with More Info: <u class="underline-offset-2 text-blue-600">...read more</u>
  }

  // Return the original description with skillfusion links removed if it doesn't need trimming
  return desc.replace(
    /(https?:\/\/)?(www\.)?(blog\.)?skillfusion\.ai[A-Za-z0-9\-._~!$&'()*+,;=:@/?#[\]%]* ?/gi,
    ""
  );
};

const FusionCard: FC<any> = ({
  link,
  price,
  priceFrequency,
  coverPhoto,
  name,
  views,
  downloads,
  description,
  isRecommended,
  owned,
  id,
  attemptPurchase,
  toolCategories,
  exampleOutputLink,
  mode
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const navigate = useNavigate();

  const [purchaseButtonText, setPurchaseButtonText] = useState<string>('Try This Tool');
  const [purchaseMessage, setPurchaseMessage] = useState<string>("");

  const handleExampleOutputClick = () => {
    window.location.href = link;
  };

  const {
    setSelectedCategoryId,
    setSearchTerm,
    categories,
    rerenderCategoriesList,
    hideCategoriesMenuIfMobile
  } = useContext(PageSearchContext);

  return (
    <div className={
      mode === 'preview'
      ? 'relative w-full text-black bg-white border-2 grid grid-cols-[auto_1fr] gap-x-3 shadow-lg w-full h-full max-h-76 overflow-hidden'
      : `relative w-full text-black bg-white border-2 grid grid-cols-[auto_1fr] lg:grid-rows-[auto_1fr] gap-x-3 shadow-lg w-full h-full max-h-76 lg:h-60 xl:h-48 overflow-hidden`
    }>
      {/* <Link
        reloadDocument
        //className={`w-full rounded-lg text-black bg-white flex-col border-2 rounded-md shadow-lg w-full flex h-full rounded-3xl hover:shadow-blue pb-6 ${(result.price != null && result.price > 0) ? 'border-yellow-600 border-2' : ''}`}
        className={`relative w-full text-black bg-white border-2 grid grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] gap-x-3 shadow-lg w-full h-full max-h-72 md:h-40 hover:shadow-blue overflow-hidden`}
        to={link}
        onClick={handleLinkClick}
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      > */}
        {isRecommended && (
          <span className="absolute top-0 right-0 bg-indigo-600 text-xs text-white pl-1.5 pr-1 rounded-bl-xl">
            Recommended
          </span>
        )}
        <div className={
          mode === 'preview'
          ? "relative flex-shrink-0 max-h-28"
          : "relative flex-shrink-0 max-h-28 lg:max-h-40 lg:row-span-4"
        }>
          <LazyLoad
            height={'100%'}
            offset={1000} 
            once
            {...(mode === 'preview' && { overflow: true })}
          >
            <img
              src={imageFailedToLoad ? missingpic : coverPhoto || missingpic}
              onError={() => setImageFailedToLoad(true)}
              className={
                mode === 'preview'
                ? "group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform object-cover h-28 w-28"
                : "group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform object-cover h-28 w-28 lg:h-60 lg:w-60 xl:h-48 xl:w-48"
              }
              alt="nc-imgs"
            />
          </LazyLoad>
        </div>
        <div className={isRecommended ? 'pt-4' : 'pt-1 lg:pt-3'}>
          <h2 className="text-lg mb-2 overflow-ellipsis max-w-full">{name}</h2>
          <p>
            <b>{downloads.toLocaleString()}</b> Users /{" "}
            <b>{views.toLocaleString()}</b> views
          </p>
          <p>
            Price:&nbsp;
            <b
              style={{ color: price > 0 ? "rebeccapurple" : "black", }}
            >
              {price === 0.0 ? 'FREE' : `${price * 10} credits ${
                  priceFrequency === "monthly" ? " per month" : ""
                } to purchase, runs on credits`
              }
            </b>
          </p>
        </div>
      <div className={
        mode === 'preview' 
        ? "col-span-2 overflow-y-scroll h-full"
        : "col-span-2 lg:col-span-1 overflow-y-scroll h-full"
      }>
          <span className="flex items-center justify-between w-full gap-2">
          <div className={
            mode === 'preview'
            ? "flex flex-col p-3 w-full"
            : "flex flex-col p-3 lg:p-0 w-full"
          }>
              <p
                className="overflow-ellipsis max-w-full text-sm"
                dangerouslySetInnerHTML={{
                  __html: trimDescriptionAndRemoveSkillfusionLinks(
                    description,
                    250
                  ),
                }}
                // onClick={() => navigate(`/fuser/${id}`)}
              ></p>
            </div>
          </span>
        </div>
      {/* </Link> */}
      <div className={
        mode === 'preview'
        ? "pr-3 pl-3 col-span-2 flex flex-col justify-between"
        : "pr-3 pl-3 col-span-2 lg:pl-0 lg:col-span-1 flex flex-col xl:flex-row justify-between xl:items-center"
      }>
          <div className="flex items-center gap-4">
            <a href={link} className="font-semibold text-violet-700 hover:font-black underline">More Info</a>
            {
              owned ?
              <button
                className="font-semibold text-indigo-700 hover:font-black underline"
                onClick={() => navigate(`/fuser/${id}`)}
              >
                Use This Tool
              </button> :
              <button
                className="font-semibold text-indigo-700 hover:font-black underline"
                onClick={async () => {
                  const previousPurchaseButtonText = purchaseButtonText;
                  setPurchaseButtonText('Purchasing...');
                  const error = await attemptPurchase();
                  setPurchaseMessage(error);
                  setPurchaseButtonText(previousPurchaseButtonText);
                }}
              >
                {purchaseButtonText}
              </button>
            }
          {exampleOutputLink && <a href={exampleOutputLink} className="underline" target="_blank">View Output</a>}
          </div>
          {/*categories && (toolCategories?.length || null) && (
            <div className="flex items-center gap-2">
              <ul className="flex flex-wrap items-center gap-x-2 gap-y-0">
                <li>
                  <p className="font-semibold text-emerald-600">Categories:</p>
                </li>
                {
                  toolCategories.slice(0,3).map((categoryId: any) => (
                    <li key={categoryId}>
                      <button
                        className="text-blue-700 underline hover:cursor-pointer"
                        onClick={() => {
                          rerenderCategoriesList(); // this opens the category disclosure element
                          hideCategoriesMenuIfMobile();
                          setSelectedCategoryId(categoryId);
                          const categoryData = categories.find(
                            (category: any) => category._id === categoryId
                          );
                          navigate(`/page-search/${categoryData?.customUrlSlug ?? categoryId}`);
                          setSearchTerm("");
                        }}
                      >
                        {categories.find(({ _id }: any) => _id === categoryId)?.catName}
                      </button>
                    </li>
                  ))
                }
              </ul>
              {(toolCategories.length > 3) && <span>...</span>}
            </div>
          )*/}
        </div>
        {purchaseMessage && <div>{purchaseMessage}</div>}
    </div>
  );
};

export default FusionCard;
