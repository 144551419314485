import React, { FC, useState, useContext } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import BlockProps from '../../models/BlockProps';
import MyToolTips from '../MyTooltip';
import FuserContext from '../../context/FuserContext';
import useRunnerFocusOnEdit from '../../hooks/useRunnerFocusOnEdit';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import { ensureNotArray, updateAtIndex, updateAtIndexRun } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { backendURL } from '../../constants/environmental';
import axios from 'axios';
import { useAuthHeader } from 'react-auth-kit';
import TextareaAutosize from 'react-textarea-autosize';
import { replacePlaceholders } from '../../utils/fuser';

const GoogleAdsBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  handleChange,
  collapsed,
}) => {
  const {
    blockStyles,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    setCollapsedBlocks,
    setStillRunning,
  } = useContext(FuserContext);

  const [requestMode, setRequestMode] = useState('fetch-data');

  const authHeader = useAuthHeader()();

  useRunnerFocusOnEdit(block, index);
  useBlockRunner(submitRequest, index);

  if (collapsed) {
    if (block.data.response)
      return (
        <>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, 'Google Ads response')}</div>
          <div>
            {truncateAfter(MAX_PREVIEW_CHARS, block.data.response.toString())}
          </div>
        </>
      );
    else return <div>Google Ads block</div>;
  }

  return (
    <FuserLoader
      name='Google Ads Block'
      loading={isLoading}
    >
      <div
        className={blockStyles}
        key={index}
      >
        {runnerMode ? (
          <p>Google Ads Block</p>
        ) : (
          <div className='mb-4 flex flex-col gap-2'>
            <label className='flex gap-2 items-center'>
              <p>Entity:</p>
              <select
                name='entity'
                onChange={handleChange}
                value={block.data.entity}
              >
                <option value='campaign'>Campaign</option>
                <option value='ad_group'>Ad Group</option>
              </select>                
            </label>

            <label className='flex gap-2 items-center'>
              <p className='shrink-0'>Attributes:</p>
              <MyToolTips
                content='
                  <p>Enter a space separated list of attributes to return</p> 
                  <p>For example:</p>
                  <p>- campaign.id</p>
                  <p>- campaign.name</p>
                  <p>- campaign.bidding_strategy_type</p>
                  <p>- campaign_budget.amount_micros</p>
                '
                tipID={'response-tooltip'}
                datatooltipplace='right'
              />
              <AutocompleteTextarea
                className='w-full'
                containerClassName='w-full flex items-center'
                autosize={true}
                block={block}
                index={index}
                onChange={handleChange}
                name='attributes'
                value={block.data.attributes ?? ''}
              />
            </label>
              
            <label className='flex gap-2 items-center'>
              <p className='shrink-0'>Metrics:</p>
              <MyToolTips
                content='
                  <p>Enter a space separated list of metrics to return</p> 
                  <p>For example:</p>
                  <p>- metrics.cost_micros</p>
                  <p>- metrics.clicks</p>
                  <p>- metrics.impressions</p>
                  <p>- metrics.all_conversions</p>
                '
                tipID={'response-tooltip'}
                datatooltipplace='right'
              />
              <AutocompleteTextarea
                className='w-full'
                containerClassName='w-full flex items-center'
                autosize={true}
                block={block}
                index={index}
                onChange={handleChange}
                name='metrics'
                value={block.data.metrics ?? ''}
              />
            </label>

            <div className='flex gap-2 items-center'>
              <label className='flex gap-2 items-center'>
                <p>From:</p>
                <input
                  type='date'
                  name='startDate'
                  onChange={handleChange}
                  value={block.data.startDate}
                />
              </label>

              <label className='flex gap-2 items-center'>
                <p>To:</p>
                <input
                  type='date'
                  name='endDate'
                  onChange={handleChange}
                  value={block.data.endDate}
                />
              </label>
            </div>

            <button
              onClick={onGetDataClick}
              className={testButtonStyles}
            >
              Get data
            </button>
          </div>
        )}

        <p>Response:</p>
        <div className='w-full'>
          <TextareaAutosize
            className='resize-none max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
            value={block.data.response}
            readOnly
          />
        </div>
      </div>
    </FuserLoader>
  );

  async function submitRequest() {
    switch (requestMode) {
      case 'fetch-data':
        await onGetDataClick();
        break;
      case 'create-campaign':
        await onCreateCampaignClick();
        break;
      default:
        throw new Error(`Invalid request mode given to submitRequest: ${requestMode}`)
    }
  }

  async function onCreateCampaignClick() { 

  }

  async function onGetDataClick() {
    setIsLoading(true);

    const attributes = ensureNotArray(
      replacePlaceholders(block.data.attributes, blocks)
    ).trim();
    const metrics = ensureNotArray(
      replacePlaceholders(block.data.metrics, blocks)
    ).trim();
    const { entity, startDate, endDate } = block.data;

    const requestUrl = new URL(`${backendURL}/google/ads`);
    requestUrl.searchParams.set('entity', entity);
    requestUrl.searchParams.set('attributes', attributes);
    requestUrl.searchParams.set('metrics', metrics);
    if (startDate) requestUrl.searchParams.set('startDate', startDate);
    if (endDate) requestUrl.searchParams.set('endDate', endDate);

    try {
      const googleAdsResponse = await axios.get(
        String(requestUrl),
        { headers: { Authorization: authHeader } }
      );

      block.data.response = JSON.stringify(googleAdsResponse.data);
    } catch (error) {
      console.log(error);
      setStillRunning(false);
      return;
    } finally {
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }

    if (runnerMode) {
      const collapseAfterRunning = [true, undefined].includes(
        block.collapseAfterRunning
      );
      setCollapsedBlocks((currentCollapsedBlocks: any) => [
        ...(collapseAfterRunning ? [block.id] : []),
        ...currentCollapsedBlocks,
      ]);
    }

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved Google Ads block at index: ${index}`,
    ]);

    setBlocks(
      (runnerMode ? updateAtIndexRun : updateAtIndex)(index, block, blocks)
    );
  }
};

export default GoogleAdsBlock;
