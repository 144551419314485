import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../constants/environmental';

const TemplatesPage: FC<any> = () => {
  const authHeader = useAuthHeader()();

  const user = useAuthUser();
  const userId = user()?.id;
  const navigate = useNavigate();

  // redirects user if not logged in
  useEffect(() => {
    if (!userId) {
      console.log('Not logged in');
      navigate('/login');
    }
  });

  const [templates, setTemplates] = useState<any[]>();

  async function fetchTemplates() {
    try {
      const response: any = await axios.get(`${backendURL}/blocks/templates`, {
        headers: {
          Authorization: authHeader,
        },
      });
      console.log(response);
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates', error);
    } finally {
    }
  }

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Templates
        </h2>
        <div>
          {templates?.map(({ _id, name, description }) => {
            return (
              <div key={_id}>
                <Link
                  to={`/fuser/template/${_id}`}
                  className='text-blue-600 underline'
                >
                  {name}
                </Link>
                <p>Description: {description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemplatesPage;
